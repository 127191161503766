import {useWeb3React} from "@web3-react/core";
import React from "react";
import {MetamaskConnector, ResetWalletconnectConnector, WalletconnectConnector} from "../../walletconnect/connectors";
import './Timestamp.css'
import {UploadFile} from "./utils/upload-file";
import { UploadFilePRO } from "./utils/upload-file-pro";
import {initWeb3} from "../../contract_utils/web3_functions";

interface TimestampProps {
  onId: (id: string, as_id:string, processing: boolean) => void
  basicData: (data:object) => void
  entity: any
  onPage?: (data:string) => void
}

export const Timestamp = (props: TimestampProps) => {

    // Gives us access to Web3 context variables -- about your current account, MM connection,...
    const { active, account, library, connector, activate, deactivate, chainId } = useWeb3React()

    let isPro = props.entity.hasOwnProperty("name");

    const connectWalletConnectSimple = async () => {
        try {
            ResetWalletconnectConnector(WalletconnectConnector);
            await activate(WalletconnectConnector);
        } catch (ex) {
            console.log(ex);
        }
    };

    const connectMetamaskSimple = async () => {
        try {
            await activate(MetamaskConnector);
            initWeb3();
        } catch (ex) {
            console.log(ex);
        }
    };

    const disconnectFromMetamask = async () => {
        if (active) {
            try {
                await deactivate();
            } catch (ex) {
                console.error(ex);
            }
        }
    }

    const switchNetwork = async () => {
        try {
            await library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x5" }]
            });
        } catch (e) {
            console.log(e)
        }
    }
    

    return (
       <div>
        { !isPro && 
            <div>
                <UploadFile onId={(id: string, processing: boolean) => {
                    props.onId(id, "", processing)
                }} basicData={props.basicData} entity={props.entity}/>
            </div>
        } 

        {isPro && 
            <div>
                <UploadFilePRO onId={props.onId} basicData={props.basicData} entity={props.entity} onPage={props.onPage}/>
            </div>
        } 
        </div>
    );
}
