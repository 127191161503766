import React, { useEffect, useState } from "react";
import Loading from "../../components/Auth0/Loading"
import { Store } from 'react-notifications-component'
import ManagerTable from "./ManagerTable";
import { Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

interface ManagerData{
    notifData: any
}

const Manager = (props: ManagerData) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [tabType, setTabType] = useState('pending')

    const handleChangeTab = (event:any)  => {
       
        let tabType = 'pending'

        if(event === 1){
            tabType = 'approved';
            setTabType('approved');
        }
        else if(event === 2){
            tabType = 'blocked';
            setTabType('blocked');
        }

        fetch('/managerData?type=' + tabType, {
            method: "GET",
           
        }).then(async (resp) => {
            if(!resp.ok) {
                let data = await resp.text();
                return Promise.reject(data);
            } else {
                let data = await resp.json();
                setData(data.data);
                setLoading(false);
            }
        }).catch((reason) => {
            setLoading(false);
            Store.addNotification({
                title: "Error",
                message: reason.toString(),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true,
                    pauseOnHover: true,
                }
            })
        })
    };

    useEffect(() => {
        handleChangeTab(0);
    }, [props.notifData])
  
    return (

        <div className="grid-content-left main__container" >
                {/* <!-- MAIN TITLE STARTS HERE --> */}

                <div className="main__title">
                    <div className="main__greeting">
                        <h1>Manage onboarding users</h1>
                    </div>
                </div>

                {/* <!-- MAIN TITLE ENDS HERE --> */}

                <div className="main__tab">
                    <fieldset>
                        <Tabs onChange={handleChangeTab}>
                            <TabList className="main__list">
                                <Tab>Pending</Tab>
                                <Tab>Approved</Tab>
                                <Tab>Blocked</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    {loading && <Loading/>}
                                    { !loading && <>
                                        <ManagerTable approvable tabType={tabType} data={data} removeEntry={(email: string) => {
                                            setData(data.filter((el: any) => {
                                                return el.email !== email
                                            }))
                                        }}/>
                                    </>}
                                    
                                </TabPanel>
                                <TabPanel>
                                    {loading && <Loading/>}
                                    { !loading && <>
                                        <ManagerTable tabType={tabType} data={data} removeEntry={(email: string) => {
                                            setData(data.filter((el: any) => {
                                                return el.email !== email
                                            }))
                                        }}/>
                                    </>}
                                </TabPanel>
                                <TabPanel>

                                    {loading && <Loading/>}
                                    { !loading && <>
                                        <ManagerTable tabType={tabType} data={data} removeEntry={(email: string) => {
                                            setData(data.filter((el: any) => {
                                                return el.email !== email
                                            }))
                                        }}/>
                                    </>}
                                    
                                </TabPanel>
                            </TabPanels>
                            
                        </Tabs>
                    </fieldset>
                </div>
            
        </div>
 
    )
   
}

export default Manager;
