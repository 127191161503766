
interface MsgBoardData {
    entity: any,
}

const MsgBoard = (props: MsgBoardData) => {

    let msg = "";

    if(props.entity.pending){
        msg = "Your account is pending to be approved."
    }

    return (
        <>
        {props.entity.pending ?
            <div className='msg__board'>
                <p>Your account is pending for approval. </p>
                <p> All Pro features will be available after approval.</p>
          </div>
        : <></>}
        
        {!props.entity.pending && !props.entity.approved ?
            <div className='msg__board'>
                <p>Your account was DENIED for approval. </p>
          </div>
        :<></>}

        </>
    );
};

export default MsgBoard;
