import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import {JWS} from "../signer/jws"
import { getMetamaskSigner } from "../signer/metamask";

// Alert notification
import { Store } from 'react-notifications-component';
import FooterText from "../Footer/FooterText";

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button } from "@chakra-ui/react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck} from '@fortawesome/free-solid-svg-icons'
import config from "../../config.js";
const defaultImage = require ("../../img/unavailable.png");

let serverUrl = window.location.origin;

function showError(msg:string){
    Store.addNotification({
        title: "Error",
        message: msg,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
        duration: 8000,
        onScreen: true
        }
    })
}


export default function VerifyResult(props: any) {

    // navigate for <Router> context, to change url
    const navigate = useNavigate();

    const [titleName, setTitleName]= useState("");
    const [description, setDescription] = useState("");
    const [url_external, setUrl_external] = useState("");
    const [brand_url, setBrand_url] = useState("");
    
    const [transaction, setTransaction] = useState("");
    const [asset_id, setAsset_id] = useState("");
    const [authAddress, setAuthAddress] = useState("");
    const [SN, setSN] = useState("N/A");
    const [transLink, setTransLink] = useState("")
    const [productImage, setProductImage] = useState("");

    const [animation, setAnimation] = useState("");
    const [youtube, setYoutube] = useState("");
    const [authorship, setAuthorship] = useState("");
    const [auth_desc, setAuthDesc] = useState("");
    const [brand, setBrand] = useState("");
    const [brand_desc, setBrandDesc] = useState("");
    const [date, setDate] = useState("");

    const [cert, setCert] = useState("");
    const [websiteBrand, setWebsiteBrand] = useState("");

    const [proNft, setProNft] = useState(false);


    let nft_id = props.data;

    // Get author address
    var url = serverUrl + '/getNft';
    let formData = {
        "nft_id": props.data,
        "req_type": "get_verify"
    };

    useEffect(() => {
        // fetch all about given nft
        fetch(url,{
            method:"POST",
            headers: {
                accept: 'application.json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(async response => {

            // check for error response
            if (!response.ok ) {
                // get error message from body or default to response status
                console.log("Error in response code")
                const error = await response.text();
                return Promise.reject(error);

            }else{

                const data1 = await response.text();
                const data = JSON.parse(data1);

                console.log(data);
                if(data.success) {
                    
                    try {
                        setTitleName(data["details"]["nft_meta_data"]["name"]);
                        setDescription(data["details"]["nft_meta_data"]["description"].split("[Proof of authorship]")[0]);
                        setUrl_external(data["details"]["nft_meta_data"]["external_url"]);
                        setAsset_id(data["details"]["asset_id"]);
                        setSN(data["details"]["serial_num"]);
                        setTransaction(data["details"]["tx_hash_mint"]);
                        setAuthAddress(data["details"]["address"]);
                        setTransLink(config.etherscan_link + data["details"]["tx_hash_mint"]);

                        let fileName: string = data["details"]["file_name"];
                        if(fileName.indexOf("image") !== -1) {
                            setProductImage("data:" + fileName + ";base64," + data["details"]["file"]);
                        }

                        let fileNameSN: string = data["details"]["thumbnail_img"];
                        if(fileNameSN.indexOf("image") !== -1) {
                            setProductImage(fileNameSN);
                        }
                        

                        if(data["details"]["brand"] !== "" ){
                            setProNft (true);
                        }

                        if(data["details"]["authorship"]) {
                            let jws = new JWS(data["details"]["authorship"] as string);
                            let auth_data = jws.payload.statement;
                            console.log(auth_data);
                            setBrand(auth_data["brand"]);
                            setBrandDesc(auth_data["brand_desc"]);
                            setAuthorship(auth_data["author"]);
                            setAuthDesc(auth_data["author_desc"]);
                            setDate(auth_data["date"]);
                            setCert(auth_data["certificate_url"]);
                            setWebsiteBrand(auth_data["brand_url"]);
                        }

                        if(data["details"]["brand_url"] !== ""){
                            setBrand_url(data["details"]["brand_url"]);
                        }

                    } catch (error:any) {
                        showError(error.toString());
                    }
                    

                } else {
                    throw new Error(data1);
                }
            }
        })
        .catch(error => {
            console.error("Error in API Issue: ", error);
            showError(error.toString());
        });
    },[]);

    return (
        <section id="nft-details" className="content-wrap">

            {props.entity.hasOwnProperty("name") ? 
            <>
            <div className="col-content nft-details w-100">
                <div className="descriptions-wrap">
                    <div className="nft-section">

                        <div className="nft-content">
                            <h2>{titleName}</h2>
                        </div> 

                        <div className="nft-content">
                            <div className="nft-section product-picture">
                            {<img src={(productImage === "") ? defaultImage: productImage} alt="product-picture" ></img>}
                            </div>
                        </div>  

                        <Accordion allowToggle key="ntf_info" className="accordionSpace">

                            <AccordionItem>
                                    <h3 className="nft-content-title">
                                        <AccordionButton >
                                            <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                 &#160;NFT INFORMATION 
                                            </Box>
                                        
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h3>
                                    <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">NFT ID</div>
                                                <div>{nft_id}</div>
                                            </div>
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">ASSET ID</div>
                                                <div>{asset_id}</div>
                                            </div>
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            { SN !== "" ?
                                                <div className="col">
                                                    <div className="label">SERIAL NUMBER</div>
                                                    <div>{SN}</div>
                                                </div>
                                            : <></>}  
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">TRANSACTION </div>
                                                <div><a href={transLink} target="_blank">{transaction}</a></div>
                                            </div>
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">AUTHOR ADDRESS</div>
                                                <div>{authAddress}</div>
                                            </div>
                                        </Box>
                        
                                    </AccordionPanel>
                                 
                            </AccordionItem>
                
                        </Accordion>  

                      
                        { brand !== ""  ? <>
                            <Accordion allowToggle key="auth_info" className="accordionSpace" defaultIndex = {0}>

                                <AccordionItem>
                                    
                                    <h3 className="nft-content-title">
                                        <AccordionButton >
                                            <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                 &#160;AUTHORSHIP STATEMENT 
                                            </Box>
                                        
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h3>
                                    <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                        <Box as="span" flex='1'  >
                                            { SN !== "" ?
                                                <div className="col">
                                                    <div className="label">SERIAL NUMBER</div>
                                                    <div>{SN}</div>
                                                </div>
                                            : <></>
                                            }   
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">AUTHOR</div>
                                                <div>{authorship}</div>
                                            </div>
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">BRAND</div>
                                                <div>{brand}</div>
                                            </div> 
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            {date !== "" ?  
                                                <div className="col">
                                                    <div className="label">DATE</div>
                                                    <div>{date}</div>
                                                </div>
                                            :<></>}
                                        </Box>

                                        <Box as="span" flex='1' >
                                            {description !== "" ?  
                                                <div className="col">
                                                    <div className="label">ITEM DETAILS</div>
                                                    <div>{description}</div>
                                                </div>
                                            :<></>}
                                        </Box>

                                        <Box as="span" flex='1' >
                                            {url_external !== "" ?  
                                                <div className="col">
                                                    <div className="label">URL LINK TO ITEM</div>
                                                    <div><a href={url_external} target="_blank">{url_external}</a></div>
                                                </div>
                                            :<></>}
                                        </Box>
                        
                                    </AccordionPanel>
                                    
                                </AccordionItem>
                            </Accordion>  
                        </>:<></>}
                        
                        { brand !== ""  ? <>
                            <Accordion allowToggle key="auth_desc" className="accordionSpace">
                                <AccordionItem>
                                    <h3 className="nft-content-title">
                                        <AccordionButton >
                                            <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                &#160;AUTHOR'S DESCRIPTION 
                                            </Box>
                                        
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h3>

                                    <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                        <Box as="span" flex='1' >
                                            <div>{auth_desc}</div>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>  
                    
                            <Accordion allowToggle key="brand_desc" className="accordionSpace">
                                <AccordionItem>
                                    <h3 className="nft-content-title">
                                        <AccordionButton >
                                            <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                &#160;BRAND DESCRIPTION
                                            </Box>
                                        
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h3>

                                    <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                        <Box as="span" flex='1' >
                                            <div>{brand_desc}</div>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>  
                        </>:<></>}

                                    
                        <Accordion allowToggle key="brand_links" className="accordionSpace" defaultIndex = {0}>
                            <AccordionItem>
                                <h3 className="nft-content-title">
                                    <AccordionButton >
                                        <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                            &#160;OTHER INFORMATION
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h3>

                                <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                    <Box as="span" flex='1' >
                                    <ul>
                                        {cert !== "" && <li><a href={cert} target="_blank">CERTIFICATE OF AUTHENTICITY</a></li>}
                                        {websiteBrand !== "" && <li><a href={websiteBrand} target="_blank">BRAND WEBSITE</a></li>}
                                        {animation !== "" && <li><a href={animation} target="_blank">ANIMATION</a></li>}
                                        {youtube !== "" && <li><a href={youtube} target="_blank">YOUTUBE VIDEO</a></li>}

                                    </ul>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                       
                    </div> 
                </div>
            </div>
            </>

            :<>
            <div className="col-visual col-visual-verify w-50">
                <div className="visual-wrap">
                    <div className="visual-paragraph">
                        AUTHORSHIP DETAILS
                    </div>
                </div>
                <FooterText />
            </div>


            <div className="col-content nft-details w-50">
                <div className="descriptions-wrap">
                    <div className="nft-section">

                        <div className="nft-content">
                            <h2>{titleName}</h2>
                        </div> 

                        <div className="nft-content">
                            <div className="nft-section product-picture">
                            {<img src={(productImage === "") ? defaultImage: productImage} alt="product-picture" ></img>}
                            </div>
                        </div>   
                        
                        <Accordion allowToggle key="ntf_info" className="accordionSpace" defaultIndex = {0}>

                            <AccordionItem>
                                    <h3 className="nft-content-title">
                                        <AccordionButton >
                                            <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                &#160;NFT INFORMATION 
                                            </Box>
                                        
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h3>
                                    <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">NFT ID</div>
                                                <div>{nft_id}</div>
                                            </div>
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">ASSET ID</div>
                                                <div>{asset_id}</div>
                                            </div>
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            { SN !== "" ?
                                                <div className="col">
                                                    <div className="label">SERIAL NUMBER</div>
                                                    <div>{SN}</div>
                                                </div>
                                            : <></>}  
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">TRANSACTION </div>
                                                <div><a href={transLink} target="_blank">{transaction}</a></div>
                                            </div>
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">AUTHOR ADDRESS</div>
                                                <div>{authAddress}</div>
                                            </div>
                                        </Box>

                                    </AccordionPanel>
                            </AccordionItem>
                        </Accordion>  

                        { brand !== ""  ? <>
                            <Accordion allowToggle key="auth_info" className="accordionSpace" defaultIndex = {0}>

                                <AccordionItem>
                                    
                                    <h3 className="nft-content-title">
                                        <AccordionButton >
                                            <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                 &#160;AUTHORSHIP STATEMENT 
                                            </Box>
                                        
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h3>
                                    <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                        <Box as="span" flex='1'  >
                                            { SN !== "" ?
                                                <div className="col">
                                                    <div className="label">SERIAL NUMBER</div>
                                                    <div>{SN}</div>
                                                </div>
                                            : <></>
                                            }   
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">AUTHOR</div>
                                                <div>{authorship}</div>
                                            </div>
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            <div className="col">
                                                <div className="label">BRAND</div>
                                                <div>{brand}</div>
                                            </div> 
                                        </Box>

                                        <Box as="span" flex='1'  >
                                            {date !== "" ?  
                                                <div className="col">
                                                    <div className="label">DATE</div>
                                                    <div>{date}</div>
                                                </div>
                                            :<></>}
                                        </Box>

                                        <Box as="span" flex='1' >
                                            {description !== "" ?  
                                                <div className="col">
                                                    <div className="label">ITEM DETAILS</div>
                                                    <div>{description}</div>
                                                </div>
                                            :<></>}
                                        </Box>

                                        <Box as="span" flex='1' >
                                            {url_external !== "" ?  
                                                <div className="col">
                                                    <div className="label">URL LINK TO ITEM</div>
                                                    <div><a href={url_external} target="_blank">url_external</a></div>
                                                </div>
                                            :<></>}
                                        </Box>
                        
                                    </AccordionPanel>
                                    
                                </AccordionItem>
                            </Accordion>  
                        </>:<>
                            {description !== "" ?  
                                <Accordion allowToggle key="auth_info" className="accordionSpace" defaultIndex = {0}>
                                    <AccordionItem>
                                        <h3 className="nft-content-title">
                                            <AccordionButton >
                                                <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                    &#160;ITEM DESCRIPTION
                                                </Box>
                                            
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h3>
                                        <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                            
                                            <Box as="span" flex='1' >
                                                <div>{description}</div>
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>  
                            :<></>}
                        </>}
                        
                        { brand !== ""  ? <>
                            <Accordion allowToggle key="auth_desc" className="accordionSpace">
                                <AccordionItem>
                                    <h3 className="nft-content-title">
                                        <AccordionButton >
                                            <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                &#160;AUTHOR'S DESCRIPTION 
                                            </Box>
                                        
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h3>

                                    <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                        <Box as="span" flex='1' >
                                            <div>{auth_desc}</div>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>  
                    
                            <Accordion allowToggle key="brand_desc" className="accordionSpace">
                                <AccordionItem>
                                    <h3 className="nft-content-title">
                                        <AccordionButton >
                                            <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                                &#160;BRAND DESCRIPTION
                                            </Box>
                                        
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h3>

                                    <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                        <Box as="span" flex='1' >
                                            <div>{brand_desc}</div>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>  
                        </>:<></>}

                                    
                        <Accordion allowToggle key="brand_links" className="accordionSpace" defaultIndex = {0}>
                            <AccordionItem>
                                <h3 className="nft-content-title">
                                    <AccordionButton >
                                        <Box as="span" flex='1' textAlign='left' fontSize='x-large' fontWeight='400'>
                                            &#160;OTHER INFORMATION
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h3>

                                <AccordionPanel pb={4}  _expanded={{ bg: '	#FF1473', color: 'white' }} >
                                    <Box as="span" flex='1' >
                                    <ul>
                                        {cert !== "" && <li><a href={cert} target="_blank">CERTIFICATE OF AUTHENTICITY</a></li>}
                                        {websiteBrand !== "" && <li><a href={websiteBrand} target="_blank">BRAND WEBSITE</a></li>}
                                        {animation !== "" && <li><a href={animation} target="_blank">ANIMATION</a></li>}
                                        {youtube !== "" && <li><a href={youtube} target="_blank">YOUTUBE VIDEO</a></li>}

                                    </ul>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div> 
                </div>
            </div>
            </>
            }
            
        </section>
    )

}


/*
<li><a href={serverUrl + "/v1/metadata/"+nft_id} target="_blank">Metadata</a></li>
<li><a href={serverUrl + "/v1/assets/"+asset_id} target="_blank">Assets</a></li>
*/
