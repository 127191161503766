import React, {useState} from "react";
import FooterText from "../Footer/FooterText";
import { RJSFSchema } from "@rjsf/utils";

const Pdf = require ("./Nebula-ring/CERTIFICATE_OF_AUTHENTICITY_AND_CARE.pdf");
const productImage = require ("../Jewelry/Nebula-ring/Ring.PNG");

export default function OrionNebulaRing() {

  let schema: RJSFSchema = {
    title: "Rings",
    description: "Ring description",
    type: "object",
    required: ["firstName", "lastName"],
    properties: {
      firstName: {
        type: "string",
        title: "First name",
        default: "Janez"
      },
      lastName: {
        type: "string",
        title: "Last name",
        default: "Novak"
      },
      telephone: {
        type: "string",
        title: "Telephone",
        minLength: 10
      }
    }
  }

  let example ={
    "title" : "ORION NEBULA RING",
    "disabled": true,
    "schema": schema,
    "formData": "",

  }

  const params = new URLSearchParams(window.location.search);
  const [file, setFile] = useState()

  /*
  function handleChange(event:any) {
    setFile(event.target.files[0])
  }*/


  return (

    <section id="nft-details" className="content-wrap">
        <div className="col-visual col-visual-verify w-50">
            <div className="visual-wrap">
                <div className="visual-paragraph">
                    AUTHORSHIP DETAILS
                </div>
            </div>
            <FooterText />
        </div>

      <div className="col-content nft-details w-50">
        <div className="descriptions-wrap">
          <div className="nft-section">

            <div className="nft-content">
                <h2>{example.title}</h2>
            </div> 
     
            <div className="nft-content">
                <div className="nft-section product-picture">
                    {<img src={productImage} alt="product-picture" ></img>}
                </div>
            </div>    

            <div className="nft-content">
                <h3>NFT INFORMATION</h3>
                <div className="label">NFT ID</div>
                <strong><a href="https://goerli.etherscan.io/token/0xc46399da201b4e6dd717f310444263de6a42e4ef?a=47361938158022790573914700179424438323055603310215589644205763252218095504851" target="_blank">473619...504851</a></strong>
            </div>
        
            <div className="nft-content">
                <h3>AUTHORSHIP STATEMENT</h3>
                <div className="col">
                    <div className="label">ASSET NAME</div>
                    <strong>Orion Nebula ring</strong>
                </div>
                <div className="col">
                    <div className="label">BRAND</div>
                    <strong>MIMOTHO – YOUR JEWELRY ARCHITECT</strong>
                </div>
                <div className="col">
                    <div className="label">AUTHOR NAME</div>
                    <strong>Metka Mikuletič</strong>
                </div>
                <div className="col">
                    <div className="label">PRODUCTION DATE</div>
                    <strong>OCT 2020</strong>
                </div>
                <div className="col">
                    <div className="label">ASSET ID</div>
                    <strong>6460840 001/1</strong>
                </div>
                <div className="col">
                    <div className="label">ASSET MATERIAL:</div>
                    <strong>Rose gold 585/000 5,65ct faceted pear shape one-of-a-kind melody stone, 2,50ct custom-cut faceted amethyst, 0,11ct white diamonds</strong>
                </div>
            </div>
        
            <div className="nft-content">
            <h3>AUTHOR’S DESCRIPTION</h3>
            <p>Metka Mikuletič is a Slovenian-based architect, who has always been fascinated by small-scale projects rather than big ones and preferred precious materials rather than concrete.
                After completing her master's degree in architecture, she continued her education as a goldsmith, currently working within her own jewelry brand. 
                "As an architect, I'm always seeking beauty in simple forms. To be surrounded by the most precious materials in the world and to have the honor to build with them in the shape of jewelry is a dream come true. "</p>
            </div>
        
            <div className="nft-content">
            <h3>BRAND DESCRIPTION</h3>
            <p>The foundation of every Mimotho jewelry piece is basic geometry, expanding through the colors of precious natural stones and multidimensional silver or golden forms. Simple at first glance yet structurally very complex.
                The biggest inspiration for every collection is architecture in the broadest sense - above all as a vivid space living around and within us.</p>
            </div>

            <div className="nft-content">
            <h3>OTHER INFORMATION</h3>
            <ul>
                <li><a href={Pdf} rel="noopener noreferrer" target="_blank">CERTIFICATE OF AUTHENTICITY AND CARE</a></li>
                <li><a href="https://mimotho.com/product/orion-nebula-ring/ " target="_blank">PRODUCT WEBSITE</a></li>
                <li><a href="https://mimotho.com/" target="_blank">BRAND WEBSITE</a></li>
                <li><a href="https://youtu.be/ezOzcm5afq4 " target="_blank">MAKING OF VIDEO</a></li>
            </ul>
            </div>
          </div>
        </div>
        <br/>
      </div>
    </section>
  );
  
}