import { useEffect, useState } from "react";
import MintPage from "./pages/MintPage";
import Cookies from 'js-cookie';
import {BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Store } from 'react-notifications-component';

import HeaderNoAuth from "./header/HeaderNoAuth";
import Loading from "./Auth0/Loading";

//Pages
import Protect from "./pages/Protect";
import MintPageFinish from "./pages/MintPageFinish";
import Verify from "./pages/Verify";
import ProPage from "./pages/ProPage";
import About from "./pages/About";
import LogoutButton from "./Auth0/Logout"
import History from './history/History';
import Onboarding from "./ProLook/Onboarding";

import VerifyPro from "./ProLook/VerifyPro";
import NavbarPro from "./ProLook/NavbarPro";
import Sidebar from "./ProLook/Sidebar";
import Dashboard from "./ProLook/Dashboard";
import NavbarOnboarding from "./ProLook/NavbarOnboarding";
import MsgBoard from "./ProLook/MsgBoard";
import Profile from "./pages/Profile";
import Manager from "./ProLook/Manager";
import Notification from "./ProLook/Notification"
import FooterText from "./Footer/FooterText";
import AboutPro from "./ProLook/AboutPro";
import MintPagePro from "./ProLook/MintPagePro";
import TermsConditions from "./Terms&Policy/Terms&conditions";
import PrivacyPolicy from "./Terms&Policy/Privacy_policy";
import Feedback from "./pages/feedback";
import ReactGA from "react-ga4";
import App1 from "./Verify/text";
import Mimotho from "./mimotho-claim/mimotho";
let serverUrl = window.location.origin;

// Work only in dev
if(serverUrl === "http://localhost:3006"){
  serverUrl = "http://localhost:3000";
}

ReactGA.initialize("G-KGP6BBNJLK");


export const LandingPage = () => {

    // console.log("--- Rendering landing page ---");

    let currentPageGrid = window.location.pathname;

    // search for nft 
    const [searchText, setSearchText] = useState(""); 

    // For PRO version - navigation control
    const [sidebarOpen, setsidebarOpen] = useState(false);
    const openSidebar = () => {
      setsidebarOpen(!sidebarOpen);
    };
    const closeSidebar = () => {
      setsidebarOpen(false);
    };

    // Timestamp ID
    const [timestampId, setTimestampId] = useState("");
    const [asId, setAsId] = useState("");
    const [processing, setProcessing] = useState(false);

 
    function setId(id: string, as_id: string, processing: boolean) {
        setTimestampId(id);
        setAsId(as_id);
        setProcessing(processing);
    }

    function basicData(data: object) {
        // console.log(data);
        setmData(data);
    }

    // Notifications - for user approval
    const [notifNum, setNotifNum] = useState(0);
    const [notifData, setNotifData] = useState();

    const [onPage, setOnPage] = useState(window.location.pathname.substring(1));

    useEffect(() => {
        if(onPage == ""){
            setOnPage("main")
        }

        if(onPage.includes("/")){
            setOnPage(onPage.substring(1));
        }
    }, [onPage])

    useEffect(() => {
        console.log("Sending to GA with page: ", onPage);
        ReactGA.event({
            category: "your category-"+onPage,
            action: "your action-"+onPage,
            label: "your label-"+onPage, // optional
            nonInteraction: true, // optional, true/false
        });
    }, [onPage]);

    // Mint ID
    const [mintId, setMintId] = useState({});
    const [mdata, setmData] = useState({});

    const [entity, setEntity] = useState({});
    const [loading,setLoading] = useState(false);
    const [session, setSession] = useState(Cookies.get('entity') || '');

    // Elements on page
    let out: JSX.Element[] = []; 

    // Can return ONLY one root element
    function addContainer(): JSX.Element {
        return (
        <>
            {out.map(element => element)}
        </>
        )
    }

    function showError(msg:string){
        Store.addNotification({
            title: "Error",
            message: msg,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
            duration: 8000,
            onScreen: true
            }
        })
    }

    // Getting data for entity
    useEffect(() => {
        if( session !== '' && !loading ){
        
            setLoading(true);

            // declare the async data fetching function
            const fetchData = async () => {
                // get the data from the api
                var url = serverUrl + '/getEntity';
                let formData = {
                    "req_type": "get_entity"
                };

                const data = await fetch(url,{
                    method:"POST",
                    headers: {
                        accept: 'application.json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                })
            
                if (!data.ok ) {
                    // get error message from body or default to response status
                    console.log("Error in response code")
                    const error = await data.text();
                    return Promise.reject(error);
                }else{
                    // convert the data to json
                    const json = await data.json();
                    // console.log(json);
                    // set state with the result
                    setEntity(json);
                    setLoading(false);
                }
            }

            // call the function
            fetchData()
            .catch(error => {
                setLoading(false);
                console.error("Error in API Issue: ", error);
                if(error.toString() === 'no such session') {
                    Cookies.remove('entity');
                    window.location.href = serverUrl;
                } else {
                    showError(error.toString());
                }
            });
                
        }
    }, [session])
      
    const[feedbackToggleT, setFeedbackToggleT] = useState(makekey(10));

    function refreshForm() {
        setFeedbackToggleT(makekey(10));
    }

    

    // PRO (user is loged in)
    if( session !== ''){

        // check if all information is loaded 
        if(loading){
            out.push(
                <BrowserRouter key="LoadingRouter">
                    <div className="container">
                        <NavbarPro  refresh={refreshForm} entity={entity} onPageChange={setOnPage} notification={notifNum} onPage={onPage} onSearchChange={setSearchText}/>
                        <main>
                            <Routes>
                                <Route path='/' element={<Loading key="Loading"/>}></Route>
                            </Routes>
                        </main>
                        <Sidebar  refresh={refreshForm} onPage={onPage} entity={entity}  onPageChange={(onPageChange) => {setOnPage(onPageChange)}} />
                    </div>
                </BrowserRouter>
            )

            return addContainer();

        }else{

            // Pro user
            if(entity.hasOwnProperty("name")) {

                console.log("inside PRO");

                out.push(
                    <BrowserRouter key="Routerr" >
                        <div className="container">
                            <NavbarPro refresh={refreshForm} entity={entity} onPageChange={setOnPage} notification={notifNum} onPage={onPage} onSearchChange={setSearchText} />
                            <main className={` grid-wrapper-pro  ${onPage}`}>
                                <MsgBoard entity={entity} />
                                <Routes>
                                    <Route path='/' element={<Dashboard  onId={setId} entity={entity} onPage={(onPage) => { setOnPage(onPage)}}   setData={basicData} searchText={searchText} />}></Route>

                                    <Route path='/dashboard' element={<Dashboard  onId={setId} entity={entity} onPage={(onPage) => { setOnPage(onPage)}}   setData={basicData} searchText={searchText} />}></Route>
                                    <Route path='/protect' element={<Protect onId={setId} progress={processing}  basicData={basicData} entity={entity} onPage={(onPage) => { setOnPage(onPage)}}  />}></Route>
                                    <Route path='/mint' element={<MintPagePro id={timestampId} asId={asId} entity={entity} onMint={setMintId} data={mdata}/>}></Route>
                                    <Route path='/mint2' element={<MintPageFinish trans_res={mintId} data={mdata} entity={entity}/>}></Route>
                                    <Route path='/history' element={<History setData={basicData} setTimestampId={(timestampId: string) => {setId(timestampId, "", false)}}/>}></Route>
                                    <Route path='/verify/:q' element={<Verify entity={entity}/>}></Route>
                                    <Route path='/verify' element={<VerifyPro />}></Route>
                                    <Route path='/about' element={<AboutPro />}></Route>
                                    <Route path='/profile' element={<Profile />}></Route>
                                    <Route path='/logout' element={<LogoutButton />}></Route>
                                    <Route path='/feedback' element={<Feedback entity={entity} key={feedbackToggleT} />}></Route>
                                    <Route path='/manager' element={<Manager notifData={notifData} />}></Route>
                                    <Route path='/terms' element={<TermsConditions entity={entity}/>}></Route>
                                    <Route path='/privacy' element={<PrivacyPolicy entity={entity}/>}></Route>
                                   
                                </Routes>
                            </main>
                            <Sidebar refresh={refreshForm} onPage={onPage} entity={entity} onPageChange={(onPageChange) => {setOnPage(onPageChange); }} />

                            <Notification entity={entity} onMsgNum={(notifNum) => {
                                    setNotifNum(notifNum);
                                }} 

                                onMsg={(data) => {
                                    setNotifData(data);
                                }}
                            />
                            
                        </div>
                    </BrowserRouter>
                )

            } else {

                console.log("inside onboarding logic");
                // Onboarding logic
                out.push(
                    <BrowserRouter key="RouterrBoarding" >
                        <NavbarOnboarding sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
                        <main>
                            <Routes>
                                <Route path='/' element={<Onboarding entity={entity}/>}></Route>            
                                <Route path='/logout' element={<LogoutButton />}></Route>
                                <Route path='/terms' element={<TermsConditions  entity={entity} />}></Route>
                                <Route path='/privacy' element={<PrivacyPolicy  entity={entity} />}></Route>
                                <Route path='/feedback' element={<Feedback entity={entity} key={feedbackToggleT} />}></Route>
                            </Routes>
                        </main>
                    </BrowserRouter>
                )
            }
            return addContainer();
        }

    }else{

        // Non PRO user
        out.push(
            <BrowserRouter key="Router" >
                <div className={`grid-wrapper-basic ${onPage}`} >
                    <div className="grid-header">
                        <HeaderNoAuth key="HeaderNoAuth" refresh={refreshForm} onCurrent={(path: string) => { setOnPage(path.substring(2)) }}/>
                    </div>

                    <Routes >
                        <Route path='/' element={<Protect onId={setId} progress={processing}  basicData={basicData} entity={entity}/>}></Route>
                        <Route path='/protect' element={<Protect onId={setId} progress={processing}  basicData={basicData} entity={entity}  />}></Route>
                        <Route path='/mint' element={<MintPage id={timestampId} onMint={setMintId} asId="" entity={entity} data={mdata}/>}></Route>
                        <Route path='/mint2' element={<MintPageFinish trans_res={mintId} data={mdata}  entity={entity}/>}></Route>
                        <Route path='/pro' element={<ProPage />}></Route>
                        <Route path='/verify/:q' element={<Verify entity={entity}/>}></Route>
                        <Route path='/verify' element={<Verify entity={entity} />}></Route>
                        <Route path='/about' element={<About />}></Route>
                        <Route path='/feedback' element={<Feedback entity={entity} key={feedbackToggleT} />}></Route>
                        <Route path='/terms' element={<TermsConditions   entity={entity}/>}></Route>
                        <Route path='/privacy' element={<PrivacyPolicy  entity={entity} />}></Route>
                    </Routes>
                </div>
                
            </BrowserRouter>
        )
        return addContainer();
    }
}

function makekey(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
