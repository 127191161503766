import React from "react";

const serverUrl = window.location.origin;

const LogoutButton = () => {
logout();

  return (
    <div></div>
  );
};

function logout(){

  //Call Api - for log out 
  window.location.href = serverUrl + "/logout"
  
}

export default LogoutButton;