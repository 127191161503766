import React from "react";
import { useNavigate } from "react-router-dom";
let serverUrl = window.location.origin;

const FooterText = () => {

    const navigate = useNavigate();

  return(
   <div className="visual-footer">
      <p>© {new Date().getFullYear()} MyNextID | All rights reserved |<span className="clickable" onClick={() => {navigate("/terms")}}> Terms of use </span>|<span className="clickable" onClick={() => {navigate("/privacy")}}> Privacy policy</span></p>
    </div>
  );
};

export default FooterText;