import React, {useState} from "react";

import {actionsP} from '../utils/ProtectActions';

export default function ProtectText() {

  return (
    <div className="visual-wrap">
      <div className="visual-paragraph">
        Protect Art, Prove & Verify NFT Authorship
      </div>
    </div>
  );
};
