import Web3 from "web3";


let web3: Web3 | undefined;

export class TxHashObject {
    blockHash: string | null | undefined;
    blockNumber: number | null | undefined;
    status: boolean | null | undefined;
    transactionHash: string | null | undefined;
}

export class AuthorshipStatementObj {
    address!: string;
    assetId!: string;
    secret!: string;
}

export const initWeb3 = () => {
    if (window.ethereum) {
        console.log("ETH INIT");
        web3 = new Web3(window.ethereum);
    }
    else
        web3 = undefined;
}

export const GetWeb3Provider = (): Web3 | undefined => {
    return web3;
}