import { useWeb3React } from "@web3-react/core";
import { ABI } from "../../contract_utils/contract_abi";
import Web3 from "web3";
// Alert notification
import { Store } from 'react-notifications-component';
import { GetWeb3Provider, TxHashObject } from "../../contract_utils/web3_functions";
import {MetamaskConnector, ResetWalletconnectConnector, WalletconnectConnector} from "../../walletconnect/connectors";
import {initWeb3} from "../../contract_utils/web3_functions";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga4'
import config from "../../config.js";

// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);

let serverUrl = window.location.origin;

interface MintProps {
  onMint: (result: any, isMinted: boolean, loading: boolean) => void
  id: string
  asId: string
  entity: any
  data: any
}


export const Mint = (props: MintProps) => {
    console.log(props.data);

    const { active, account, library, connector, activate, deactivate, chainId } = useWeb3React();

    const [connecting, setConnecting] = useState(false);

    const assetId: string = props.id;
    let isPro = props.entity.hasOwnProperty("name");
    const asId: string = props.asId;
    
    //console.log("assetId: ",assetId);
    //console.log("basicData: ", props.data);

    // Convert asset id into crypto-js object (WordArray)
    let assetIdWeb3: any = web3.eth.abi.encodeParameter('bytes32', "0x"+assetId);
    let asIdWeb3: any = web3.eth.abi.encodeParameter('bytes32', '0x' + asId);

    /*
    const handleInputChange = (e:any) => {
        const { name, value } = e.target;

        //Desctructing to get values
        setValues({
          ...values,
          [name]: value,
        });

    };*/

    useEffect(() => {
        if(connecting) {
            setConnecting(false);
            handleSubmit();
        }
    }, [connecting])

    const connectMetamaskSimple = async () => {
        try {
            await activate(MetamaskConnector);
            initWeb3();
            // refresh this component
            setConnecting(true);
        } catch (ex) {
            console.log(ex);
        }
    };
   
    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) =>{

        // CRITICAL CODE. DO NOT TOUCH
        if(e) {
            e.preventDefault();
        }
        
        // Check all fields
        //console.log(initialValues);
        if(!chainId) {
            await connectMetamaskSimple();
            return;
        }

        /*
        if(!onlyLettersAndNumbers(values.mintName)){
            Store.addNotification({
                title: "Error",
                message: "Serial number  must contain only numbers and letters.",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 4000,
                onScreen: true
                }
            })
        }*/
        let pageAction = "basic";

        // VALIDATE 
        if (active && assetId !== "")
        
            props.onMint(null, false, true);
            
            try {
                const smartContract = new web3.eth.Contract(ABI, config.SmartContract_ADDRESS);

                // let stmtId: string = getStatementId("");
                // if (stmtId == "")
                //   return
                // NFT ID JE COMPUTED: bytes.concat(bytes20(sender), assetId);
                // AH: ja, vendar v trenutni verziji ni izracunan tako
                // Pomembno je, da je address v "from" enak addressu, ki je bil uporabljen pri timestamp. Account argument je lahko poljuben (now owner NFT-ja)
                //https://api.openft.world

                let mintAction: any
                if(isPro && asId !== "") {
                    mintAction = smartContract.methods.safeMint(account, assetIdWeb3, asIdWeb3, config.website_link).send({ from: account });
                } else {
                    // basic
                    mintAction = smartContract.methods.safeMint1(account, assetIdWeb3, config.website_link).send({ from: account });
                }


                mintAction.then((res: any) => {

                    try {
                        //console.log("RES MINT: \n", res);

                        // add metadata to database
                        let formData = {
                            "asset_id": props.id,  
                            "tx_hash_mint": res.transactionHash,
                            "req_type": "saveMint",
                        }
    
                        var url = serverUrl + '/saveNft';
    
                        // fetch data (do REST request)
                        fetch(url,{
                            method:"POST",
                            headers: {
                              accept: 'application.json',
                              'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(formData)
                        })
                        .then(async response => {
                    
                            // check for error response
                            if (!response.ok ) {
                                // get error message from body or default to response status
                                console.log("Error in response code")
                                const error = await response.text();
                                return Promise.reject(error);
    
                            }else{
                    
                                const data1 = await response.text();
                                const data = JSON.parse(data1);
                                console.log("data from minting ", data);
                                console.log("Data in props ", props.data);
                        
                                // data recieved, set issued to data, and stop loading
                                //that.setState({issued: data1, loading: false, isDisabled:false})
                                
                                if(data.success) {

                                    if (isPro){
                                        pageAction = "pro"
                                    }
                                    
                                    // GA4
                                    ReactGA.event({
                                        action: 'mint-'+pageAction+'_action',
                                        category: 'mint-'+pageAction+'_category',
                                    })

                                    // Update page
                                   props.data["image"] = data.image;
                                    props.onMint(res, true, false);
                                } else {
                                    throw new Error(data1);
                                }
    
                            }
                        })
                        .catch(error => {
                            console.error("Error in API Issue: ", error);
                            //that.state.callback("Error", error.message + ".",true);
                        });


                    } catch (error:any) {
                        throw error;
                    }
                    
                }).catch((err: any) => {
                    console.log("ERR MINT: \n", err);
                    props.onMint("", false, false);

                    Store.addNotification({
                        title: "Error",
                        message: err.message,
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    })
                
                });
                
            } catch (err:any) {
            console.log("In catch - error", err);

            Store.addNotification({
                title: "Error",
                message: err.toString(),
                onRemoval:(id: string, flag: string) => {
                    props.onMint("", false, false);
                },
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 4000,
                onScreen: true
                }
            })
        }
    }

    return (
        <form onSubmit={handleSubmit} className="form">

            <div >
                <label className="mint-label">NFT ID</label> 
                <p className="word-wrap">{props.data.nft_id}</p>
            </div>

           <br/>
            <button className="btn-primary" type="submit" >Mint your NFT</button>
        </form>
    );
}
