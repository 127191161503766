import { SubmitHandler, useForm } from "react-hook-form";
import crypto from "crypto-js"
import { ABI } from "../../../contract_utils/contract_abi";
import Web3 from "web3";
import { TxHashObject } from "../../../contract_utils/web3_functions";

import { useNavigate } from "react-router-dom";

// Alert notification
import { Store } from 'react-notifications-component';
import { useEffect, useState } from "react";

import ReactGA from 'react-ga4';
import config from "../../../config.js";
import useWallet from "../../../walletconnect/hook";

import { Buffer } from 'buffer';

type FormInputs = {
  file: FileList,
};

// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);

interface UploadFileProps {
  onId: (id: string, processing: boolean) => void
  basicData: (data:object) => void
  entity: any
}

let serverUrl = window.location.origin;

// Form elements to timestamp
const initialValues = {
    mintName: "",
    mintDesc:"",
    mintUrl:"",
}

export const UploadFile = (props: UploadFileProps) => {

    //console.log("Entity",props.entity);
    const [assetID,setAssetID] = useState('');
    const {active, account, library, connector, activate, deactivate, chainId} = useWallet();
    const [hasConnected, setHasConnected] = useState(false);
    const [fileContents, setFileContents] = useState("");
    const [fileContentsSN, setFileContentsSN] = useState("");
    const [show, setShow] = useState(false)
    const [fileName, setFileName]= useState("");
    const [fileNameSN,setFileNameSN] = useState("");

    //------------------------------------- Disable parts of form ------------------------------------

    const [emptyUpload, setEmptyUpload] = useState(true);
    const [emptyBasic, setEmptyBasic] = useState(true);
    let isPro = props.entity.hasOwnProperty("name");
    let isApproved =  false;

    if(props.entity.hasOwnProperty("approved")){
        isApproved = props.entity["approved"];
    }

    // -------------------------------------- Form elements ------------------------------------------
    const [values, setValues] = useState(initialValues);

    const handleInputChange = (e:any) => {
        const { name, value } = e.target;

        //Desctructing to get values
        setValues({
          ...values,
          [name]: value,
        });

    };

    /**
     * For button to be avaiable, mintName and mintDesc is requierd
    
     useEffect(() => {
        if(values.mintName !== "" && values.mintDesc !== ""){
            setEmptyUpload(false);
        }else{
            setEmptyUpload(true);
        }
    })*/

    /** 
     * hook, to check if has connected. CreateTimestamp connects if not connected and saves file content into this hook
     * then refresh allows useWeb3React to refresh it's constants and CreateTimestamp function has the connected
     * account's information.
    */
    useEffect(() => {
        if(hasConnected) {
            CreateTimestamp(fileContents, fileContentsSN);
            setHasConnected(false);
        }
    }, [hasConnected])

    const connectMetamaskSimple = async (fileContent: string) => {
        try {
            await activate();
            // refresh this component
            setFileContents(fileContent);
            setHasConnected(true);
        } catch (ex) {
            console.log(ex);
        }
    };

    
    // Detection if user alredy uploaded file 
    var fileInput = document.getElementById('ts-assetID');
    if(fileInput !== null) {
        fileInput.addEventListener('change', function () {
        }, false);
    }



    // navigate for <Router> context, to change url
    const navigate = useNavigate();
    const {register, handleSubmit, setError, formState: {errors}} = useForm<FormInputs>();

    function onlyLettersAndNumbers(str:any) {
        if(str.indexOf(":") !== -1){
            let element = str.split(":")[1];
            return /^[A-Za-z0-9]*$/.test(element);
        }

        return /^[A-Za-z0-9]*$/.test(str);
    }


    const onSubmitForm: SubmitHandler<FormInputs> = (data: FormInputs) => {
     
        if(fileName !== ""){
            // VALIDATE FILE SIZE < 2MB

            if (data.file.item(0)!.size > 2000000) {
            setError("file", {type: "file_size", message: "File too large. File size must be under 2MB."});

            } else {
                // READ FILE CONTENT
                let fileReader = new FileReader();
                fileReader.readAsArrayBuffer(data.file.item(0) as Blob);
                fileReader.onload = async (e) => {
                    try {
                        const fileContent: string | undefined = fileReader.result as string;
                            if (!fileContent || fileContent === "") {
                                setError("file", { type: "metamask_error", message: "Missing file content." })
                                return
                            }
                            
                            let newfileContent = Buffer.from(fileContent).toString('base64');
                            CreateTimestamp(newfileContent,"");
                    } catch (e) {
                        console.log(e);
                        setError("file", {type: "file_content", message: "There is something wrong with file content. Try uploading other file."})
                    }
                }
            }
        }else{

            // Read file for thumbnail image if it was provided

            if(assetID.length < 6){
                setError("file", {type: "file_size", message: "Serial number  must contain at least six characters."})
            }
            else if(!onlyLettersAndNumbers(assetID)){
                setError("file", {type: "file_size", message: "Serial number  must contain only numbers and letters."})
            }else{
                if(fileNameSN !== ""){
                
                    // VALIDATE FILE SIZE < 2MB
                    if (data.file.item(0)!.size > 2000000) {
                    setError("file", {type: "file_size", message: "File too large. File size must be under 2MB."});
        
                    } else {
                        // READ FILE CONTENT
                        let fileReader = new FileReader();
                        fileReader.readAsArrayBuffer(data.file.item(0) as Blob);
                        fileReader.onload = async (e) => {
                            try {
                                const fileContentSN: string | undefined = fileReader.result as string;
                                    if (!fileContentSN || fileContentSN === "") {
                                        setError("file", { type: "metamask_error", message: "Missing file content." })
                                        return
                                    }

                                    let newfileContentSN = Buffer.from(fileContentSN).toString('base64');
                                    CreateTimestamp("",newfileContentSN);
                            } catch (e) {
                                console.log(e);
                                setError("file", {type: "file_content", message: "There is something wrong with file content. Try uploading other file."})
                            }
                        }
                    }
                }else{
                    CreateTimestamp("","");
                }
            }
        }
    }    

    async function CreateTimestamp(fileContent: string,fileContentSN: string): Promise<void> {

        if(!web3 || !account){
            setFileContentsSN(fileContentSN);
            await connectMetamaskSimple(fileContent);
            return;
        } 

        let generated_hash;
        let typeOfData: string;

        // If asset id (serial number) is given, use it else generate it from file content
        let serialNumber = assetID;
        if(assetID !== ""){
            typeOfData = "SN";
            console.log("Generating with serial number");
            let generated_hashA: crypto.lib.WordArray = crypto.SHA256(serialNumber);
            generated_hash = generated_hashA;

        } else{
            typeOfData = "file";
            console.log("Generating file content ",fileContent,typeof fileContent);
            let generated_hashB: crypto.lib.WordArray = crypto.SHA256(fileContent);
            generated_hash = generated_hashB;
        }
    
        var _alice = null;

        if (account !== null) {
            _alice = crypto.enc.Hex.parse(account.replace('0x', ''));
        } 
        
        if(_alice !== null){

            var _nid = _alice.concat(generated_hash);
            let _nftId = crypto.SHA256(_nid);

            // Generated hash zamenjaj z assetId (poimenovanje)
            let assetId = generated_hash.toString();
           /* console.log("xx generated_hash", generated_hash.toString());
            console.log("xx _alice", _alice.toString());
            console.log("xx _nid", _nid.toString());
            console.log("xx nft id", _nftId.toString());
            */

            // Check if asset ID already exist
            let notUnique = false;
            let urlCheck = serverUrl + `/api/v1/exist?id=`+ assetId;
            const response = await fetch(urlCheck,{
                method:"GET",
                headers: {
                  accept: 'application.json',
                  'Content-Type': 'application/json'
                },
            });

            console.log("Response , ",response);
            // check for error response
            if (!response.ok ) {
                // get error message from body or default to response status
                console.log("Error in response code")
                const error = await response.text();
                throw new Error(error);

            }else{
                notUnique = await response.text() === "true";
                //const data1 = await response.text();
                if(notUnique){
                    console.log("ERROR MSG");
                    Store.addNotification({
                        title: "Error",
                        message: "Given NFT was already created !",
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                        })
                    throw new Error("Given NFT was already created !");
                }
            }

            // Pretvoris v obliko za SC
            let nftId: any = web3.eth.abi.encodeParameter('bytes32', '0x' + _nftId.toString());
        
            /*
            console.log("ASSET_ID: ", _alice.toString());
            console.log("NFT_ID: ", nftId);
            */

            props.onId(assetId, true);

            const smartContract = new web3.eth.Contract(ABI,  config.SmartContract_ADDRESS);
        
            smartContract.methods.timestamp(nftId).send({ from: account }).then((res: TxHashObject) => {

                if (!res.status) {
                    setError("file", { type: "metamask_error", message: "Error when sending transaction" })
                    props.onId(assetId, false);
                    return;
                } else {
                    
                    //Successfully timestamped - time to save data 
                    let formData = {
                        "asset_id": assetId,
                        "address": account,
                        "file":fileContent,
                        "file_name": fileName,
                        "tx_hash_ts": res.transactionHash,
                        "type": typeOfData,
                        "serial_num": serialNumber,
                        "pro":false,
                        "nft_id" : nftId,
                        "chain_id": chainId,

                        "name": values.mintName,
                        "description": values.mintDesc,
                        "external_url": values.mintUrl,
                        "image": config.website_link+"/v1/image/"+nftId,

                        "thumbnail": fileContentSN,
                        "thumbnail_name": fileNameSN,
                        /*"animation_url":values.mintAnimationUrl,
                        "youtube_url":values.mintYoutubeUrl,

                        "author":values.mintAuthor,
                        "brand":values.mintBrand,
                        "brand_desc":values.mintBrandDesc,
                        "date": values.mintDate,*/

                        "req_type": "saveTS"
                    }

                    var url = serverUrl + '/saveNft';

                    // fetch data (do REST request)
                    fetch(url,{
                        method:"POST",
                        headers: {
                          accept: 'application.json',
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(formData)
                    })
                    .then(async response => {
                
                        // check for error response
                        if (!response.ok ) {
                            // get error message from body or default to response status
                            console.log("Error in response code")
                            const error = await response.text();
                            return Promise.reject(error);

                        }else{
                
                            const data1 = await response.text();
                            const data = JSON.parse(data1);
                    
                            // data recieved, set issued to data, and stop loading
                            //that.setState({issued: data1, loading: false, isDisabled:false})
                            
                            if(data.success) {

                                // GA4
                                ReactGA.event({
                                    action: 'protect-basic_action',
                                    category: 'protect-basic_category',
                                })

                                // Continue flow to mint stage 
                                props.onId(assetId, false);
                                props.basicData({"nft_id":nftId, "author_address":account, "asset_id": assetId , "image":config.website_link +"/v1/image/"+nftId, SN:serialNumber})
                                navigate("/mint");
                            } else {
                                throw new Error(data1);
                            }

                        }
                    })
                    .catch(error => {
                        console.error("Error in API Issue: ", error);
                        props.onId(assetId, false);
                        //that.state.callback("Error", error.message + ".",true);
                    });

                }
            }).catch((err: any) => {
                console.log(err);
                props.onId("", false);

                Store.addNotification({
                title: "Error",
                message: "Transaction rejected",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
                })
            });
        }
    }     

    useEffect(() => {
      if(errors.file) {
        Store.addNotification({
          title: "Error",
          message: errors.file.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 4000,
            onScreen: true
          }
        })
      }
    }, [errors.file]);

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="form">

            <div style={{width:"100%", display: "flex"}}>
            <div className="btn-container" style={{display:"inherit"}}>
                <label className="switch btn-color-mode-switch">
                <input  type="checkbox" name="color_mode" id="color_mode" value="1"  onClick={(e) => {setShow(show => !show); setFileName(""); setEmptyUpload(true); setAssetID("");setFileNameSN("")}}  />
                <label htmlFor="color_mode" data-on="Physical" data-off="Digital" className="btn-color-mode-switch-inner"></label>
                </label>

            </div>
            </div>

           

            {!show && <>
             <label><span className="requiredMark">*</span> Upload file</label>
            <label htmlFor="imagesUpload" className="drop-container bottom">
                <span className="drop-title">Drag your art or click to upload (max. file size 2MB)</span>

                <input  id="imagesUpload"
                        {...register("file", {
                            required: "You must upload a file."
                        })}
                        type="file"
                        onChange={(e:any) => {

                        if(e.target.files[0] !== undefined){
                            setFileName(e.target.files[0].name);
                            setEmptyUpload(false);
                        }else{
                            setFileName("");
                            setEmptyUpload(true);
                        }
                        }}
                    />
                <p id="fileName">{fileName}</p>
                </label>
                
            </>
            }

            {show && 
            <div>
                <label id="SNLabel"><span className="requiredMark">*</span> Serial number</label>
                <input type="text" name="asset" className="verify-form" id="ts-assetID" value={assetID} onChange={event => {
        
                    if(event.target.value !== ""){
                    setAssetID(event.target.value)
                    setEmptyUpload(false);
                    }else{
                    setEmptyUpload(true);
                    setAssetID("")
                    }

                }} /> 

    	        <label htmlFor="imagesUpload" className="drop-container bottom">
                    <span className="drop-title">Drag your art or click to upload (max. file size 2MB)</span>

                    <input  id="imagesUpload"
                            {...register("file", { required: false})}
                            type="file"
                            onChange={(e:any) => {
                               
                            if(e.target.files[0] !== undefined){
                                setFileNameSN(e.target.files[0].name);
                            }else{
                                setFileNameSN("");
                            }
                            }}
                        />
                    <p id="fileName">{fileNameSN}</p>
                </label>
            </div>
            }
            
            <div>
                <label><span className="requiredMark">*</span> Item name </label> 
                <input required className="verify-form" type="text"  maxLength ={140} value={values.mintName}  onChange={handleInputChange} name="mintName" />
            </div>

            <div>
                <label><span className="requiredMark">*</span> Description </label> 
                <textarea required className="verify-form"  maxLength ={400} value={values.mintDesc}  onChange={handleInputChange} name="mintDesc" />
            </div>

            <div>
                <label>External URL</label> 
                <input  className="verify-form" type="url" pattern="https?://.+" size={2048}  value={values.mintUrl}  onChange={handleInputChange} name="mintUrl" />
            </div>

            
            <button id="timestamp-nft" disabled={emptyUpload} className="btn-primary" type={"submit"}>Create Proof of Authorship</button>
            
        </form>
    )

}
