import React, {useEffect, useState}  from "react";

import AboutText from "../utils/AboutText";
import FooterText from "../Footer/FooterText";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import LoginButton from "../Auth0/Login";
import { useNavigate } from "react-router-dom";

let serverUrl = window.location.origin;

const initialValues = {
    feedback: "",
    name:"",
    email:"",
}

interface FeedbackProps {
    entity: any
    }

export default function Feedback(props:FeedbackProps) {

    const navigate = useNavigate();
    const [values, setValues] = useState(initialValues);
    const [finishText, setFinishText] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    
    const [startText, setStartText] = useState(" We're committed to helping artists prove authorship and verifying NFT authenticity for buyers and sellers. Please, share your comments to help us continue offering the best NFT experience. ")
    let isPro = props.entity.hasOwnProperty("name");
    const notify = (err:any) => toast.error(err,{theme:"colored"});

    const handleInputChange = (e:any) => {
        var { name, value } = e.target;

        //Desctructing to get values
        setValues({
          ...values,
          [name]: value,
        });

    };

    const handleSubmit = (e:any) => {
        e.preventDefault();

        let emailHolder = validateEmail(values.email);

        if(emailHolder){

            const requestOptions: RequestInit = {
                method: 'POST',
                body: JSON.stringify(values)
            };
    
            fetch(serverUrl  + '/api/v1/feedback', requestOptions).then(async response => {
    
                // check for error response
                if (!response.ok ) {
                    // get error message from body or default to response status
                    console.log("Error in response code")
                    const error = await response.text();
                    return Promise.reject(error);
    
                }else{
    
                    const data1 = await response.text();
                    const data = JSON.parse(data1);
                    
                    if(data.success) {        
                        try {

                            // What form was pressed?
                            let action = e.nativeEvent.submitter.id;

                            // GA4
                            ReactGA.event({
                                action: action+'_action',
                                category: action+'_category',
                            })

                            setStartText("");
                            setFinishText("Thank you for taking time to provide feedback. We appreciate hearing from you and will review your comments carefully.");
                            setIsSubmitted(true);
                        } catch (error:any) {
                            throw new Error(error.toString());
                        }
                        
                    } else {
                        throw new Error(data1);
                    }
                }
            })
            .catch(error => {
                console.error("Error in API Issue: ", error);
                notify(error.toString());
    
            });
        }else{
            notify("Your email address is invalid. \nEnter valid email address.");
        }
        
    }

 
  return (
    
    <section className="content-wrap">
        <ToastContainer />

        { !isPro ?
        <>
            <div className="col-visual col-visual-about w-50">
                <div className="visual-wrap">
                    <div className="visual-paragraph">
                    Share your Feedback and Help Us Become Better
                    </div>
                </div>

                <FooterText />
            </div>

            <div className="col-content  w-50">
                <div className="cards-wrap contain-form">
                    <div className="cards">
                        <div className="heading">
                        
                            {/* <div className="heading-title">Feedback form</div> */}

                            <div className="heading-description">
                               {startText}
                            </div>
                                
                            <div className="heading-description">
                                {finishText}
                            </div>
                        </div>

                        <div>
                            {!isSubmitted && ( 
                                <form action="/" className="form" onSubmit={handleSubmit}>
                                    

                                    <div>
                                        <label>Name</label> 
                                        <input  className="verify-form" type="label" maxLength={60}  value={values.name}  onChange={handleInputChange} name="name" />
                                    </div>


                                    <div>
                                        <label>Email </label> 
                                        <input  className="verify-form" type="email"  maxLength={60}  value={values.email}  onChange={handleInputChange} name="email" />
                                    </div>


                                    <div>
                                        <label>Do you have any suggestions to improve MyNextNFT service?</label> 
                                        <textarea id="feedback_form" className="verify-form" required maxLength={600} placeholder="What is on your mind?" name="feedback" value={values.feedback} onChange={handleInputChange}/>
                                    </div>

                                    <button id="feedback-basic" className=" btn-primary" >Submit your feedback</button>
                                        
                                </form>
                            )}

                            { isSubmitted && (<>
                                <br/>
                                <p className="pro-link">
                                    Elevate your NFT game with MyNextNFT PRO! Sign up now to access
                                    exciting additional features and protect your art with proof of authorship.
                                    Don't wait – unleash your creativity today!<br/><br/>
                                </p>
    
                                <div >
                                    <LoginButton/>
                                </div>
                            </>)}    
                            
                        </div>
                    </div>

                </div>
            </div>


        </>:

            <div className="grid-content-left main__container" >
                    <div className="cards">
                        <div className="heading">
                        
                            {/* <div className="heading-title">Feedback form</div> */}

                            <div className="heading-description">
                               {startText}
                            </div>
                                
                            <div className="heading-description">
                                {finishText}
                            </div>
                        </div>

                        <div>
                            {!isSubmitted && ( 
                                <form action="/" className="form" onSubmit={handleSubmit}>
                                    

                                    <div>
                                        <label>Name</label> 
                                        <input  className="verify-form" type="label" maxLength={60}  value={values.name}  onChange={handleInputChange} name="name" />
                                    </div>


                                    <div>
                                        <label>Email </label> 
                                        <input  className="verify-form" type="email"  maxLength={60}  value={values.email}  onChange={handleInputChange} name="email" />
                                    </div>


                                    <div>
                                        <label>Do you have any suggestions to improve MyNextNFT service?</label> 
                                        <textarea id="feedback_form" className="verify-form" required maxLength={600} placeholder="What is on your mind?" name="feedback" value={values.feedback} onChange={handleInputChange}/>
                                    </div>

                                    <button id="feedback-basic" className=" btn-primary" >Submit your feedback</button>
                                        
                                </form>
                            )}

                            { isSubmitted && (<>

                                <div >
                                    <form action="/dashboard" className="form" key="1">
                                        <button key="back-to-dashboard" className=" btn-primary" onClick={() => {navigate("/dashboard")}} >Back to dashboard</button>
                                    </form>
                                </div>
                            </>)}    
                            
                        </div>
                    </div>
                </div>  
            }  
    </section>
  );
};

function validateEmail(email:string) {
  
    var regex = new RegExp("^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$", );
    var rex = regex.test(email);
    return rex;
}