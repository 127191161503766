import React, {useEffect, useState} from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useWeb3React } from "@web3-react/core";
import { ABI } from "../../contract_utils/contract_abi";
import Web3 from "web3";

import { useNavigate } from "react-router-dom";

// Alert notification
import { Store } from 'react-notifications-component';

import {MetamaskConnector, ResetWalletconnectConnector, WalletconnectConnector} from "../../walletconnect/connectors";

import {initWeb3} from "../../contract_utils/web3_functions";
import { SHA256 } from "crypto-js";
import crypto from "crypto-js";
import { ethers } from "ethers";
import { Buffer } from 'buffer';
import { getJsonWalletAddress } from "ethers/lib/utils";

import MetamaskSignatures from "@metamask/eth-sig-util";

import {JWS} from '../signer/jws';
import {getMetamaskSigner} from '../signer/metamask';
import ReactGA from 'react-ga4';
import VerifyBrand from "./VerifyBrand";
import config from "../../config.js";

type FormInputs = {
  file: FileList
};

// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);
let serverUrl = window.location.origin;

function showError(msg:string){
    Store.addNotification({
        title: "Error",
        message: msg,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
        duration: 8000,
        onScreen: true
        }
    })
    
}

/*
const verifyMessage = async ({ message, address, signature }) => {
    try {
      const signerAddr = await ethers.utils.verifyMessage(message, signature);
      if (signerAddr !== address) {
        return false;
      }
  
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
};*/

export default function VerifyForm(props: any) {

    const [show, setShow] = useState(false)
    const [fileName, setFileName]= useState("");
    const [uploadedfile, setUploadedFile]= useState("");
    const [emptyUpload, setEmptyUpload] = useState(true);

    // const [SN,setSN] = useState('');
    const [ValueSN, setValueSN] = useState('');
    const [BrandSN, setBrandSN] = useState('');

    const SN = () => {
        if(BrandSN !== '') {
            return BrandSN + ":" + ValueSN;
        }
        return ValueSN;
    }

    const [assetID,setAssetID] = useState('');
    const [addressID, setAddressID] = useState('');

    // navigate for <Router> context, to change url
    const navigate = useNavigate();

    const {register, handleSubmit, setError, formState: {errors}} = useForm<FormInputs>();

    const {active, account, library, connector, activate, deactivate, chainId} = useWeb3React();

    // connection state, to allow component refresh when connecting to web3
    const [hasConnected, setHasConnected] = useState(false);
    const [fileContents, setFileContents] = useState("");

    useEffect(() => {
      if(hasConnected) {
          console.log("file contents ",fileContents);
        CreateTimestamp(fileContents);
        setHasConnected(false);
      }
    }, [hasConnected])

    const connectMetamaskSimple = async (fileContent: string) => {
        try {
            await activate(MetamaskConnector);
             initWeb3();
             setFileContents(fileContent);
             setHasConnected(true);
        } catch (ex) {
            console.log(ex);
        }
    };

    function onlyLettersAndNumbers(str:any) {
        if(str.indexOf(":") !== -1){
            let element = str.split(":")[1];
            return /^[A-Za-z0-9]*$/.test(element);
        }

        return /^[A-Za-z0-9]*$/.test(str);
    }

    function displayTimestamp(){
        var el = document.getElementById("protect-error");
        if(el !== null){
            el.style.display =  'none';
        }
    }

    const onSubmitForm: SubmitHandler<FormInputs> = (data: FormInputs) => {

        if(fileName !== ""){
            // VALIDATE FILE SIZE < 2MB
            if (data.file.item(0)!.size > 2000000) {
                setError("file", {type: "file_size", message: "File too large. File size must be under 2MB."});
            } else {
                // READ FILE CONTENT
                let fileReader = new FileReader();
                fileReader.readAsArrayBuffer(data.file.item(0) as Blob);
                fileReader.onload = async (e) => {
                    try {
                        const fileContent: string | undefined = fileReader.result as string;
                            if (!fileContent || fileContent === "") {
                                setError("file", { type: "metamask_error", message: "Missing file content." })
                                return
                            }
                            let newfileContent = Buffer.from(fileContent).toString('base64');
                            CreateTimestamp(newfileContent);
                    } catch (e) {
                        console.log(e);
                        setError("file", {type: "file_content", message: "There is something wrong with file content. Try uploading other file."})
                    }
                }
            }
        }else{
            if(ValueSN.length < 6){
                setError("file", {type: "file_size", message: "Serial number  must contain at least six characters."})
            }
            else if(!onlyLettersAndNumbers(ValueSN)){
                setError("file", {type: "file_size", message: "Serial number  must contain only numbers and letters."})
            }else{
                CreateTimestamp("");
            }
        }
    }    

    async function CreateTimestamp(fileContent : string): Promise<void> {

        //console.log("file " + fileContent)

        if (!web3 || !library) {
            await connectMetamaskSimple(fileContent);
            return;
        } 

        // Wait until wallet is connected ! 

        // GA4
        ReactGA.event({
            action: 'verify-'+props.pageMode+'_action',
            category: 'verify-'+props.pageMode+'_category',
        })

        
        let generated_hash: crypto.lib.WordArray;

        // If asset id is given, use it else generate it from file content
        if(SN() !== ""){
            console.log("Generating with asset ID", SN());

            //Check for brand

            let generated_hashA: crypto.lib.WordArray = crypto.SHA256(SN());
            generated_hash = generated_hashA;

        } else{
            console.log("Generating file content");
            let generated_hashB: crypto.lib.WordArray = crypto.SHA256(fileContent);
            generated_hash = generated_hashB;
        }
         
        setAssetID(generated_hash.toString());

        // Get author address
        var url = serverUrl + '/getNft';

        // to stop errors
        let assetId = generated_hash.toString();
        let formData = {"serial_num": SN(), "file":fileContent, "req_type":"get_address"};
        let nftId = "";
        let addressID = ""

        // fetch author address  (do REST request)
        await fetch(url,{
            method:"POST",
            headers: {
                accept: 'application.json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(async response => {
    
            // check for error response
            if (!response.ok ) {
                // get error message from body or default to response status
                console.log("Error in response code")
                const error = await response.text();
                return Promise.reject(error);

            }else{
    
                const data1 = await response.text();
                const data = JSON.parse(data1);
                
                if(data.success) {

                    console.log("Verify address, "+data1);
                    addressID = data.address;
                    nftId = data.nft_id;
                     //Calculate via assetID
                    if(assetId !== ''  && addressID !==""){

                        try {
                
                            let assetID_Byte: any = web3.eth.abi.encodeParameter('bytes32', '0x' + assetId.toString());
                            const smartContract = new web3.eth.Contract(ABI,  config.SmartContract_ADDRESS);

                            //check if you are using the right channel & smart contract 
                            if( config.SmartContract_CHANNEL !== chainId ){
                                showError( "Your wallet is not on "+config.SmartContract_ChannelName+" chain.");
                                return
                            }else{
                                smartContract.methods.getNftId(assetID_Byte).call().then(async (res: any) => {
                                    let _alice = crypto.enc.Hex.parse(addressID.replace('0x', ''));
                                    var _nid = _alice.concat(generated_hash);
                                    let _nftId = crypto.SHA256(_nid);
    
                                    if(res.toString() === '0x' + _nftId.toString()) {
                                        // Basic verification OK
                                        Store.addNotification({
                                            title: "Success",
                                            message: "Verified",
                                            type: "success",
                                            insert: "top",
                                            container: "top-center",
                                            animationIn: ["animate__animated", "animate__fadeIn"],
                                            animationOut: ["animate__animated", "animate__fadeOut"],
                                            dismiss: {
                                                duration: 8000,
                                                onScreen: true
                                            }
                                            })
        
                                            //navigate to new tab
                                            navigate("/verify/"+nftId);
        
                                            return
                                    } else if (data.authorship) {
                                        // Pro verification
                                        let _alice = crypto.enc.Hex.parse(addressID.replace('0x', ''));
                                        let jws_hash: crypto.lib.WordArray =  crypto.SHA256(data.authorship as string);
                                        var _nid1 = generated_hash.concat(jws_hash);
                                        var _nidsha = crypto.SHA256(_nid1);
                                        var _nid = _alice.concat(_nidsha);
                                        let _nftId = crypto.SHA256(_nid);
    
                                        if(res.toString() === '0x' + _nftId.toString()) {
                                            let jws = new JWS(data.authorship as string);
    
                                            let metamaskSigner = await getMetamaskSigner(window.ethereum, addressID);
                                            metamaskSigner.setVerificationAddress(addressID);
    
                                            if(await jws.verify(metamaskSigner)) {
                                                // Pro verification ok + signature ok
                                                Store.addNotification({
                                                    title: "Success",
                                                    message: "Verified with Authorship statement",
                                                    type: "success",
                                                    insert: "top",
                                                    container: "top-center",
                                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                                    dismiss: {
                                                        duration: 8000,
                                                        onScreen: true
                                                    }
                                                    })
                
    
                                                    //navigate to new tab
                                                    navigate("/verify/"+nftId);
                
                                                    return
                                            } else {
                                                showError( "Authorship statement signature incorrect");
                                                return
                                            }
                                        } else {
                                            showError( "Authorship statement error");
                                            return
                                        }
                                    } else {
                                        showError( "Not verified");
                                        return
                                    }
    
                                }).catch((err: any) => {
                                    console.log(err);
                                    showError(err.message);
                                });
                            }
                    
                        } catch (error: any) {
                
                            console.log(error)
                            showError(error.message);
                        }
            
                    }else{
                        showError("Empty asset ID or file");
                    }
 
                } else {
                    throw new Error(data1);
                }
            }
        })
        .catch(error => {
            console.error("Error in API Issue: ", error);

            if(error === "no rows in result set"){
                var el = document.getElementById("protect-error");
                if(el !== null){
                    el.style.display =  'block';
                }
            }

            showError(error.toString());
        });

       
    }

    useEffect(() => {
      if(errors.file) {
        Store.addNotification({
          title: "Error",
          message: errors.file.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 4000,
            onScreen: true
          }
        })
      }
    }, [errors.file]);

    return (

      <div>
        {(
          <React.Fragment>
              <div >
                <form onSubmit={handleSubmit(onSubmitForm)}  className="form">

                    <div style={{width:"100%", display: "flex", marginBottom:"20px"}}>
                        <div className="btn-container">
                            <label className="switch btn-color-mode-switch">
                                <input  type="checkbox" name="color_mode" id="color_mode" value="1"  onClick={(e) => {setShow(show => !show); setFileName(""); setEmptyUpload(true); setValueSN("")}} />
                                <label htmlFor="color_mode" data-on="Physical" data-off="Digital" className="btn-color-mode-switch-inner bottom"></label>
                            </label>
                        </div>
                    </div>
                    
                    {!show && <>
                        <label><span className="requiredMark">*</span> Upload file</label>
                        <label htmlFor="imagesUpload" className="drop-container bottom">
                            <span className="drop-title"> Drag your art or click to upload (max. file size 2MB)</span>

                            <input  id="imagesUpload"
                                    {...register("file", {
                                        required: "You must upload a file."
                                    })}
                                    type="file"
                                    onChange={(e:any) => {

                                    if(e.target.files[0] !== undefined){
                                        setFileName(e.target.files[0].name);
                                        setEmptyUpload(false);
                                        displayTimestamp();
                                    }else{
                                        setFileName("");
                                        setEmptyUpload(true);
                                    }
                                    }}
                                />
                                <p id="fileName">{fileName}</p>
                        </label>

                    </>
                    }

                    {show && <>
                        <div className="flex" >
                            <div id="resetID">
                                <VerifyBrand onBrand={(value: string) => {
                                    console.log("Setting brand", value);
                                    setBrandSN(value);
                                }}/>
                            </div>
                             <div style={{fontSize: "30px", paddingTop:"14px"}}>&nbsp;:&nbsp;</div> 
                            <div>
                                <label><span className="requiredMark">*</span> Serial number</label>
                                <input type="text" name="asset" className="verify-form" id="ts-assetID-verify" placeholder="Serial number" value={ValueSN} onChange={event => {
                        
                                    if(event.target.value !== ""){
                                        setValueSN(event.target.value)
                                        setEmptyUpload(false);
                                        displayTimestamp();
                                    }else{
                                        setEmptyUpload(true);
                                        setValueSN("");
                                    }
                                }} /> 
                            </div>
                        </div>

                    </>}


                   <button disabled={emptyUpload} className="btn-primary btn-verify" type={"submit"}>Verify NFT</button> 

                   { props.pageMode !== "pro" && 
                    <p id="protect-error"><br></br>Protect your creation with<strong><a href={serverUrl} className="link-text"> TIMESTAMP</a></strong></p>
                    } 

                </form>
              </div>
          </React.Fragment>
        )}
      </div>
  )

}
