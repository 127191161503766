
import { useWeb3React} from '@web3-react/core';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import Web3 from "web3";
import {useEffect, useState} from "react";
import config from '../config.js';
import {MetamaskConnector} from "./connectors";
import {initWeb3} from "../contract_utils/web3_functions";
import Channel from '@nodeguy/channel'


// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);
// react hook to get connection with metamask

function useAsyncWeb3React() {
    const {active, account, library, connector, activate, deactivate, setError, chainId} = useWeb3React();

    const channel = Channel<Web3ReactContextInterface<any>>();

    const onChange = async () =>  {
        return await channel.shift();
    }

    useEffect(() => {
        // trigger the change
        let error: Error;
        channel.push({active, account, library, connector, activate, deactivate, chainId, setError})
    }, [active])

    return {active, account, library, connector, activate, deactivate, chainId, onChange}
}


export default () => {

    const {active, account, library, connector, activate, deactivate, chainId, onChange} = useAsyncWeb3React();

    const [switching, setSwitching] = useState(false);

    const connectMetamaskSimple = async () => {
        try {
            setSwitching(true);
            await activate(MetamaskConnector);
            initWeb3();
            const newState = await onChange();
            if(newState.active && newState.chainId !== config.SmartContract_CHANNEL) {
                await newState.library.provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: config.chain_id }]
                }).catch((e: any) => {
                    console.log("Disconnecting");
                    disconnectFromMetamask();
                    setSwitching(false);
                })
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    const disconnectFromMetamask = async () => {
        if (active) {
            try {
                await deactivate();
            } catch (ex) {
                console.error(ex);
            }
        }
    }

    useEffect(() => {

        if(active && chainId !== config.SmartContract_CHANNEL && !switching) {
            library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: config.chain_id }]
            }).catch((e: any) => {
                if(e.code !== -32002) {
                    disconnectFromMetamask();
                }
            });
        }
    }, [chainId])

    return {active, account, library, connector, 'activate': connectMetamaskSimple, 'deactivate': disconnectFromMetamask, chainId}
}