import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSwatchbook} from '@fortawesome/free-solid-svg-icons'
import { faWandMagicSparkles} from '@fortawesome/free-solid-svg-icons'
import { faPassport} from '@fortawesome/free-solid-svg-icons'
import { faCircleInfo} from '@fortawesome/free-solid-svg-icons'
import { faClipboard} from '@fortawesome/free-solid-svg-icons'

export const  SidebarData = [
    {
        title: "Dashboard",
        icon: <FontAwesomeIcon icon={faSwatchbook} />,
        link: "dashboard"
    },{
        title: "Create NFT",
        icon: <FontAwesomeIcon icon={faWandMagicSparkles} />,
        link: "protect"
    },{
        title: "Verify",
        icon: <FontAwesomeIcon icon={faPassport} />,
        link: "verify"
    },{
        title: "Feedback",
        icon: <FontAwesomeIcon icon={faCircleInfo} />,
        link: "feedback"
    },{
        title: "About",
        icon: <FontAwesomeIcon icon={faCircleInfo} />,
        link: "about"
    }
]

export const  SidebarUser = [
    {
        title: "Manager",
        icon: <FontAwesomeIcon icon={faClipboard} />,
        link: "manager"
    }
]
