import React from 'react';
// Form

// Alerts
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import './App.css';
// import './css/style.css';
import './scss/style.scss';
import './css/mashup.scss';
//import './css/wallet/style.css';

import {LandingPage} from "./components/Landing-page";
import { ChakraProvider } from '@chakra-ui/react';

/*
import ReactGA from "react-ga4";

ReactGA.initialize([
    {
      trackingId: "your GA measurement id",
      gaOptions: {}, // optional
      gtagOptions: {}, // optional
    },
    {
      trackingId: "your second GA measurement id",
    },
]);
*/

export const App = () => {
    return (
        <ChakraProvider>
          <ReactNotifications />
            <div className="App">
                <LandingPage/>
            </div>
        </ChakraProvider>
        
    );
}
