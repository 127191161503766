  import React, {useState}  from "react";

import {Mint} from '../mint/Mint';
import ProtectText from "../utils/protectText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa1, fa2, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import FooterText from "../Footer/FooterText";
import { useNavigate } from "react-router-dom";
import Loading from "../Auth0/Loading";


interface MintPageProps {
    onMint? : (result: any) => void
    id : string
    asId: string
    entity: any
    data: object
  }

  
export default function MintPagePro(props: MintPageProps) {
 
  if(props.id === "") {
    window.location.href = "/"    
  }

  // navigate for <Router> context, to change url
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onMint = (result: any, isMinted: boolean, loading: boolean) => {
    if(isMinted) {
      if(props.onMint) {
        //TODO: get URL from result
        props.onMint(result);
      }
      navigate("/mint2");
    } else {
      setLoading(loading);
    }
  }
  return (

    <div className="grid-content-left main__container" >
        <div className="main__title">
            <div className="main__greeting">
                <div className="heading-step">Step 2 of 2</div>
                <div className="heading-title">Mint NFT</div>
                {(!loading) ? 
                    <div className="heading-description">
                        You have successfully completed step 1 and protected your art.
                        Proceed & mint a first-of-a-kind NFT containing proof of authorship,
                        confirming you as the original creator. 
                    </div>
                    :<></>
                }
            </div>
        </div>
    

        {(!loading) ? 
            <div className="cards cards-3">
                <div >
                    <Mint id={props.id} asId={props.asId} entity={props.entity} onMint={onMint} data={props.data} />
                </div>
                  
              </div>
              :
              <div className="cards cards-3">
              
                <div >
                    <h2 className="transactionTitle">Transaction in progress...</h2>
                </div>

                <div >
                  <Loading />
                </div>
                  
              </div>
            }
    </div>

  );
};
