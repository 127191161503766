import React from "react";
import { useLinkClickHandler } from 'react-router-dom';

interface LinkProps {
    to :string,
    onClick? : () => void,
    children: React.ReactNode
}

export default function Link(props :LinkProps) {

    const clickHandler = useLinkClickHandler(props.to);

    const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if(props.onClick) {
            props.onClick();
        }
        clickHandler(event)
    }

    return (
        <a onClick={onClick} >
            {props.children}
        </a>
    )
}