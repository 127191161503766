
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
import { Store } from 'react-notifications-component';

let serverUrl = window.location.origin;

//Work only in dev
if(serverUrl == "http://localhost:3006"){
  serverUrl = "http://localhost:3000";
}

interface Boarding {
  entity: any
}

// Form elements for brand
const initialValues = {
    organization: "",
    name:"",
    email: "",
    url:"",
}

function showError(msg:string){
    Store.addNotification({
        title: "Error",
        message: msg,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
        duration: 8000,
        onScreen: true
        }
    })
    
}

export default function Onboarding (props: Boarding) {

    //------------------------------------- Disable parts of form ------------------------------------
    const [emptyBasic, setEmptyBasic] = useState(true);

    // -------------------------------------- Form elements ------------------------------------------
    const [values, setValues] = useState(initialValues);

    useEffect(() => {
        setValues({...values, email: props.entity["email"]});
    }, [props.entity])

    useEffect(() => {
        if( values.organization != '' && values.name != '') {
            setEmptyBasic(false);
        }else{
            setEmptyBasic(true);
        }
    }, [values])

    const handleInputChange = (e:any) => {
        const { name, value } = e.target;

        //Desctructing to get values
        setValues({
          ...values,
          [name]: value,
        });

    };

    function onlyLettersAndNumbers(str:any) {
        if(str.indexOf(":") !== -1){
            let element = str.split(":")[1];
            return /^[A-Za-z0-9]*$/.test(element);
        }

        return /^[A-Za-z0-9]*$/.test(str);
    }


    const handleSubmit = (e: { preventDefault: () => void; } | undefined) => {
        
        /*if(!onlyLettersAndNumbers(values.name) && !onlyLettersAndNumbers(values.organization)){
            setError("file", {type: "Input", message: "Serial number  must contain only numbers and letters."})
        }else{
            CreateBrand();
        }*/

        CreateBrand(e);
    }    

    async function CreateBrand(e: { preventDefault: () => void; } | undefined): Promise<void> {

        if(e) {
            e.preventDefault();
        }

        let out= {
            "brand_meta_data": values,
            "name":values.organization,
            "email": values.email,
            "req_type":"saveEntity"
        }


        let cookie = Cookies.get('entity');
        if(cookie) {
            const headers: HeadersInit = { 'Content-Type': 'application/json', 'Authorization':cookie }
            const requestOptions: RequestInit = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(out)
            };

            fetch(serverUrl  + '/saveEntity', requestOptions).then(async response => {
    
                // check for error response
                if (!response.ok ) {
                    // get error message from body or default to response status
                    console.log("Error in response code")
                    const error = await response.text();
                    return Promise.reject(error);
    
                }else{
    
                    const data1 = await response.text();
                    const data = JSON.parse(data1);
                    
                    if(data.success) {
                        
                        try {
                            window.location.href = serverUrl;
                        } catch (error:any) {
                            showError(error.toString());
                        }
                        
                    } else {
                        throw new Error(data1);
                    }
                }
            })
            .catch(error => {
                console.error("Error in API Issue: ", error);
                if(error.toString() === 'no such session') {
                    Cookies.remove('entity');
                    window.location.href = serverUrl;
                } else {
                  showError(error.toString());
                }
            });
        }
            
    }     

    return (
        <section key="sectionOnboarding" className="content-wrap">
            <div id="onboarding" className="col-content w-100">
                <form onSubmit={handleSubmit} className="form form-onboarding">
                    <h2>Let’s get started</h2>
                    <div className="subtitle">We need a few information to begin</div>
                    <br/>
                    <label><span className="requiredMark">*</span> Required fields</label>

                    <div className="form-group">
                        <label htmlFor="organization">Name of your organization<span className="requiredMark">*</span></label>
                        <input type="text" value={values.organization} placeholder="Organization" name="organization" onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="full-name">Your full name<span className="requiredMark">*</span></label>
                        <input type="text" value={values.name} placeholder="Full name" name="name" onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input disabled type="text" value={values.email ||''} name="email"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="website">Website</label>
                        <input type="url" value={values.url} placeholder="Website" name="url" onChange={handleInputChange}/>
                    </div>
                    <br/>
                    <button id="timestamp-nft" disabled={emptyBasic} className="btn-primary" type={"submit"}>Continue</button>
                </form>
            </div>
        </section>      
    )
}


