

export const navItemsNoAuth = [

  {
    id: 1,
    title: "CREATE NFT",
    path: "./",
    cName: "",
    func: "",
  },{
    id: 2,
    title: "VERIFY",
    path: "./verify",
    cName: "",
    func: "",
  },{
    id: 3,
    title: "PRO",
    path: "./pro",
    cName: "",
    func: "",
  },{
    id: 4,
    title: "FEEDBACK",
    path: "./feedback",
    cName: "",
    func: "onclick(props.refresh())",
  },{
    id: 5,
    title: "ABOUT",
    path: "./about",
    cName: "",
    func: "",
  },
];

export const serviceDropdown = [
  {
    id: 0,
    title: "Disconnect",
    path: "",
    cName: "disconnect-wallet",
  },{
    id: 1,
    title: "Connect your wallet",
    path: "",
    cName: "connncet-wallet",
  }
]
