import { ethers } from "ethers";
import { hexToString, stringToHex } from "web3-utils";
import {Base64} from 'js-base64'

import {JWS, JWSSigner, JWSHeader} from "./jws"

export class MetamaskSigner implements JWSSigner {
    signer: ethers.providers.JsonRpcSigner
    address: string
    verifyAddr?: string

    constructor(signer: ethers.providers.JsonRpcSigner, address: string) {
        this.signer = signer
        this.address = address
        this.verifyAddr = address
    }

    async sign(payload: string): Promise<[JWSHeader, string]> {
        let header: JWSHeader = {
            alg: "ES256K"
        }
        const hexSignature = await this.signer.signMessage(payload);

        return [header, Base64.encodeURL(hexSignature)]
    }

    async verify(jws: JWS): Promise<boolean> {
        if(jws.header) {
            if(jws.header.alg !== "ES256K") {
                throw "algorithm is incorrect"
            }
        } else {
            throw "no header"
        }
        if(jws.signature) {
            const address = await ethers.utils.verifyMessage(jws.encodePayload(), jws.signature)
            return address === this.address
        }
        throw "no signature"
    }

    setVerificationAddress(address: string) {
        this.verifyAddr = address;
    }
}

export async function getMetamaskSigner(ethereum: any, address?: string): Promise<MetamaskSigner> {
    if (!ethereum)
        throw new Error("No crypto wallet found. Please install it.");
    await ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    let addr = await signer.getAddress();
    if(address) {
        addr = address
    }
    return new MetamaskSigner(signer, addr)
}