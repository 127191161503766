import React from "react";

const Profile = () => {

  return (
    <>
        <div> Not yet available</div>
    </>
  );
};

export default Profile;