import React, {useState}  from "react";

import AboutText from "../utils/AboutText";
import FooterText from "../Footer/FooterText";
import LoginButton from "../Auth0/Login";
// import currentRisk from "../../img/currentRiskDemo.jpg"

export default function About() {


  return (
    
    <section className="content-wrap">
      <div className="col-visual col-visual-about w-50">
          <AboutText/>
          <FooterText />
      </div>


      <div className="col-content about w-50">
        <div className="content">
        <div className="details-section">
            
            
            <div className="details-content">
              <strong>Welcome to MyNextNFT, the first platform to introduce »proof of authorship«, providing security and assurance for all parties involved in the sale of NFTs.</strong>
            </div>
            
            <div className="details-content">
              <p>With it, artists can prove they're the original creators, and buyers can verify the NFT and have peace of mind. MyNextNFT is free, open and available to everyone, providing unmatched security and assurance in the NFT world. Say goodbye to fraud and hello to secure and verified NFT transactions!</p>
            </div>

            <div className="details-content">
              <h3>What are NFTs?</h3>
              <p>NFTs represent everything from original music and artwork to collectibles, tickets, and in-game items. What makes them special is that they are non-fungible, meaning that their content is rare, unique, and can't be replaced or exchanged with something else. As the latest craze in the art world, NFTs are changing the game for both artists and buyers alike, enabling creators to authenticate their work and collectors to acuqire exclusive digital value.</p>
            </div>

            <div className="details-content">
              <h3>What are the risks of current NFT minting method?</h3>
              <p>If you're looking to explore the exciting world of NFTs, it is important to understand the drawbacks of current NFT minting method. In this process, NFTs and their assets are often bound with mutable URLs, which can be tampered with, making it difficult for creators to prove the authenticity of their work. Additionally, the current model doesn't provide adequate proof of authorship, leaving both artists and buyers vulnerable to fraudulent activity. Lastly, there is a lack of secure binding of physical items with NFTs. Staying informed about these risks is crucial for ensuring your NFTs are secure and reliable.</p>
              {/* <img src={currentRisk}  title="Text that shows on hover over image"></img>
              <figcaption>Name of img if needed</figcaption> */}
            </div>     

            <div className="details-content">
              <h3>How is MyNextNFT different?</h3>
              <p>NFTs are in high demand, but current minting methods do not fully protect creators, buyers, and sellers from malicious actors. MyNextNFT's approach solves this problem by allowing creators to timestamp a "proof of authorship" in a pre-mint phase, which provides security for all parties involved in the sale of NFTs. When the NFT is minted, the “proof of authorship” gives the author control over the address, helping them prove they are the original creators, and sellers and buyers to verify the authenticity of the NFT.</p>
            </div>    

            <div className="details-content">
              <h3>MyNextNFT Public BETA</h3>
              <p>MyNextNFT offers a basic service that is completely free to help support the NFT community and empower artists and sellers in their NFT sales. For those who want to take their NFT game to the next level, we also offer MyNextNFT PRO, which unlocks additional features based on your specific needs. Play around with our BETA release and send us your feedback. Thank you!</p>
            </div>          

          </div>
        </div>
      </div>
    </section>
  );
};
