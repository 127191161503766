import { stringify } from "querystring";
import React from "react";
import ReactGA from 'react-ga4';

const onClick = () => {
    // GA4
    ReactGA.event({
        action: 'login-pro_action',
        category: 'login-pro_category',
    })
}

export class LoginButton extends React.Component{
  
  render(){
    return (
     
      <form action="/login" className="form" key="1">
          <button key="Login-button" className=" btn-primary" onClick={onClick} >Try MyNextNFT PRO</button>
      </form>
    );
  }
}

export default LoginButton;
