import { propNames } from '@chakra-ui/react';
import { useState} from 'react';
import { Link, useNavigate } from "react-router-dom";

import logo_mynextID from "../../img/logoMynextNFT.svg";
import WalletConnect from '../Wallet/WalletConnect';
import {navItemsNoAuth} from "./NavItemsNoAuth";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faX } from '@fortawesome/free-solid-svg-icons'

let pathname = window.location.pathname;
if(pathname === "" || pathname === "/") {
  pathname = "/";
}

interface HeaderNoAuthProps {
    onCurrent?: (path: string) => void,
    refresh: (params: any) => any;
}

function HeaderNoAuth(props: HeaderNoAuthProps) {

  const [current, setCurrent] = useState("." + pathname);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

    return (
        <>
        <header className="site-header">
             <img id="logo-basic" src={logo_mynextID} alt="logo" className="logo clickable" onClick={ () => {setCurrent("./"); navigate("/protect")}}/>
 
             <nav id="basic-nav" className="site-navigation profile-dropdown-menu">
 
                <div className="mini_menu" onClick={() => {setOpen(!open)}}>
                    {!open && <a href="#"><FontAwesomeIcon icon={faBars} size='2x' /></a>}
                    {open && <>
                        <div className='dropdown_mini_menu'>
                            {navItemsNoAuth.map(item => {
                            
                                if(item.path === "./feedback"){
                                    return (
                                        <div key={item.id} className={(item.path === current) ? "active": "" }>
                                            <Link to={item.path} onClick={() => { setCurrent(item.path); props.refresh(false); if(props.onCurrent) {props.onCurrent(item.path)}}}>{item.title}</Link>
                                        </div>
                                    )
                                }

                                return (
                                <div key={item.id} className={(item.path === current) ? "active": "" }>
                                    <Link to={item.path} onClick={() => { setCurrent(item.path); if(props.onCurrent) {props.onCurrent(item.path)}}}>{item.title}</Link>
                                </div>
                                )
                                
                            })}
                        </div>
                        
                        <a href="#" style={{position:"relative"}}><FontAwesomeIcon icon={faX} size='2x' /></a>
                    </>}
                </div>
                
                <WalletConnect/>
 
                 <ul className="nav topnav profile-dropdown-nav-menu" id="myTopnav">
 
                   {navItemsNoAuth.map(item => {

                       if(item.path === "./feedback"){
                        return (
                            <li key={item.id} className={(item.path === current) ? "active": "" }>
                              <Link to={item.path} onClick={() => { setCurrent(item.path); props.refresh(false); if(props.onCurrent) {props.onCurrent(item.path)}}}>{item.title}</Link>
                            </li>
                          )
                       }else{
                        return (
                            <li key={item.id} className={(item.path === current) ? "active": "" }>
                              <Link to={item.path} onClick={() => { setCurrent(item.path); if(props.onCurrent) {props.onCurrent(item.path)}}}>{item.title}</Link>
                            </li>
                          )
                       }
                   })}
 
                 </ul>
                
             </nav>
         </header> <div className="cr cr-top cr-right cr-sticky cr-red nav-badge">BETA</div>
       </>
    );
}

export default HeaderNoAuth;