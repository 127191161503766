import { Base64 } from 'js-base64';

export interface JWSHeader {
    alg: string
}

export class JWS {
    header?: JWSHeader
    payload: any
    signature?: string


    constructor(jwt: any, header?: JWSHeader, signature?: string) {
        if(typeof jwt === 'string') {
            let cache = jwt.split(".")
            //console.log(Base64.decode(cache[0]).toString());
            this.header = JSON.parse(Base64.decode(cache[0]).toString())
            this.payload = JSON.parse(Base64.decode(cache[1]).toString())
            this.signature = Base64.decode(cache[2]).toString()
        } else {
            this.payload = jwt
            if(header) {
                this.header = header
            }
            if(signature) {
                this.signature = signature;
            }
        }
    }

    encode(): string {
        return Base64.encodeURL(JSON.stringify(this.header)) + "." + Base64.encodeURL(JSON.stringify(this.payload)) + "." + this.signature
    }

    encodePayload(): string {
        return Base64.encodeURL(JSON.stringify(this.payload))
    }

    async sign(signer: JWSSigner) {
        [this.header, this.signature] = await signer.sign(this.encodePayload())
    }

    async verify(signer: JWSSigner): Promise<boolean> {
        return await signer.verify(this)
    }
}

export interface JWSSigner {
    sign: (payload: string) => Promise<[JWSHeader, string]> 
    verify: (jws: JWS) => Promise<boolean>
}

