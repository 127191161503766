import React, {useState} from "react";
import { Timestamp } from "../timestamp/Timestamp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ProtectText from "../utils/protectText";
import Loading from "../Auth0/Loading";
import FooterText from "../Footer/FooterText"

interface ProtectProps {
    onId: (id: string, as_id: string, processing: boolean) => void
    progress: boolean
    basicData:(data:object) => void
    entity: any
    onPage?: (data:string) => void
}

export default function Protect(props: ProtectProps) {

    let isPro = props.entity.hasOwnProperty("name");
    let isApproved =  false;

    if(props.entity.hasOwnProperty("approved")){
        isApproved = props.entity["approved"];
    }

  return (<>

        { !isPro &&
        <section className="content-wrap">
            <div className="col-visual col-visual-protect w-50">
                <ProtectText />
                <FooterText />
            </div>
            <div className="col-content w-50">
                <div className="cards-wrap contain-form">

                    {!props.progress ?

                    <>
                        <div className="heading">
                            
                            <div className="heading-step">Step 1 of 2</div>
                            <div className="heading-title">Protect Art</div>
                            <div className="heading-description">
                                Easily prove your NFT’s art authenticity as an artist and verify it as a buyer. Eliminate fraud and ensure secure and verifiable transactions with MyNextNFT!
                                To create a Proof of Authorship to confirm your artwork's originality, linked to its NFT, start by choosing the type of asset you want to protect. 
                            </div>
                        </div>

                        <div className="cards">

                            <div>
                                <Timestamp onId={props.onId} basicData={props.basicData} entity={props.entity} />
                            </div>

                        </div>
                    </> : <>
                        <div className="heading">
                            <div className="heading-step">Step 1 of 2</div>
                            <div className="heading-title">Protect Art</div>
                            
                        </div>
                        <div className="cards cards-3">
                            <h2 className="transactionTitle">Transaction in progress...</h2>

                            <div>
                                <Loading />
                            </div>

                        </div>
                    </>}
                </div>
            </div>
        </section>
       } 

    { isPro &&
     
        <>
        <div className="mobile-pro">
            <h1>Protect Art, Prove & Verify NFT Authorship</h1>
        </div>
        
        <div className="grid-content-left main__container" >
            <div className="main__title">
                <div className="main__greeting">
                    
                    <div className="heading-step">Step 1 of 2</div>
                    <div className="heading-title">Protect Art</div>
                    {!props.progress ?
                        <div className="heading-description">
                            Easily prove your NFT’s art authenticity as an artist and verify it as a buyer. Eliminate fraud and ensure secure and verifiable transactions with MyNextNFT!
                            To create a Proof of Authorship to confirm your artwork's originality, linked to its NFT, start by choosing the type of asset you want to protect. 
                        </div>
                        :<></>
                    }
                </div>
            </div>

            {!props.progress ?

                <>
                    <div className="cards cards-3">
                        <div>
                            <fieldset disabled={!isApproved} >
                                <Timestamp onId={props.onId} basicData={props.basicData} entity={props.entity} onPage={props.onPage}/>
                            </fieldset>
                        </div>

                    </div>
                </> : <>
                    <div className="cards cards-3">
                        <h2 className="transactionTitle">Transaction in progress...</h2>

                        <div>
                            <Loading />
                        </div>
                    </div>
                </>
            }
        </div>
        </>
    } 

  </>);
  
};
