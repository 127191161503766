
import { Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import hello from "../../img/hello.svg";
import { Store } from 'react-notifications-component'
import Loading from "../Auth0/Loading";
import NftCards from "./NftCards";
import InfiniteScroll from "react-infinite-scroll-component";
import React from "react";
import {selectItems} from "./DashboardSelect"
import ReactGA from 'react-ga4';

interface DashboardProps {
    setData: (data: object) => void
    entity: any
    onId: (id: string, onId: string, processing: boolean) => void
    onPage?: (data: string) => void
    searchText:any
}

let perPage = 10;

const Dashboard = (props: DashboardProps) => {

    const inputRef = React.useRef(null)
    
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState<any[]>([]);

    const [dataRef, setDataRef] = useState("nft");
    //const [dataNFT, setDataNFT] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    let isApproved =  false;

    if(props.entity.hasOwnProperty("approved")){
        isApproved = props.entity["approved"];
    }

    const handleChangeTab = (event:any)  => {

        //console.log("Getting data (CHANGE TAB) ", props.searchText);
        // Reset data 
        setLoading(true);
        setItems(items.splice(0));
        setCurrentPage(1);

        let url = '/v1/api/history';
        let obj = { req_type: 'nft',per_page: perPage, page:1 , search:props.searchText}
        setDataRef("nft");

        if(event === 1){
            url = '/v1/api/history';
            obj = { req_type: 'nft_toMint', per_page: perPage, page: 1, search:props.searchText}
            setDataRef("nft_toMint");
        }
        else if(event === 2){
            url = '/v1/api/history';
            obj = { req_type: 'verify', per_page: perPage, page: 1, search:props.searchText}
            setDataRef("verify");
        }else if(event === 3){
            url = '/v1/api/history';
            obj = { req_type: 'nftAll', per_page: perPage, page: 1, search:props.searchText}
            setDataRef("nftAll");
        }

        fetch(url, {
            method: "POST",
            body: JSON.stringify(obj)
        }).then(async (resp) => {
           
            if(!resp.ok) {
                let data = await resp.text();
                return Promise.reject(data);
            } else {

                let d: [] = await resp.json();
               
                if(d === null) {
                    setHasMore(false);
                    setItems([]);

                } else {
                    if(d.length < perPage){
                        
                        setHasMore(false);
    
                    }else{
                        
                        setHasMore(true);
                        setCurrentPage(2);
                    }
                    setItems([...items, ...d]);
                }

                setLoading(false);

              
            }
        }).catch((reason) => {
            setLoading(false);
            Store.addNotification({
                title: "Error",
                message: reason.toString(),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true,
                    pauseOnHover: true,
                }
            })
        })

    };

    const getData = () => {
        fetch('/v1/api/history', {
            method: "POST",
            body: JSON.stringify(
                {
                    req_type: dataRef,
                    per_page: perPage,
                    page: currentPage,
                    search:props.searchText,
                }
            )
        }).then(async (resp) => {
            if(!resp.ok) {
                let data = await resp.text();
                return Promise.reject(data);
            } else {
                let d: [] = await resp.json();
    
                if(d === null) {
                    setHasMore(false);

                } else {
                    if(d.length < perPage){
                    
                        setHasMore(false);
    
                    }else{
                   
                        setHasMore(true);
                        setCurrentPage(currentPage + 1);
                    }
                    setItems([...items, ...d]);
                }
            }
        }).catch((reason) => {
            setLoading(false);
       
            Store.addNotification({
                title: "Error",
                message: reason.toString(),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true,
                    pauseOnHover: true,
                }
            })
        })
    }

    const nextData = (ref: string) => {
        return () => {
            if(!loading) {
                if(ref == dataRef) {
                    getData();
                }
            }
        }
    }

    useEffect(() => {
        let tab = 0;
        if(dataRef === "nft_toMint"){
            tab = 1;
        }else if(dataRef === "verify"){
            tab = 2;
        }else if(dataRef === "nftAll"){
            tab = 3;
        }
        handleChangeTab(tab);

        // GA4
        ReactGA.event({
            action: 'search-pro_action',
            category: 'search-pro_category',
        })
    }, [props.searchText])

    useEffect(() => {
        //handleChangeTab(0);
    }, [])

    return (
    
        <div id="dash" className="grid-content-left main__container">
               
                {/* <!-- MAIN TITLE ENDS HERE --> */}

                <div className="main__tab">
                    <fieldset id="onboarding__filedset" disabled={!isApproved} >

                    <Tabs onChange={handleChangeTab}>
                        <TabList className="main__list">
                            <Tab >Created</Tab>
                            <Tab>To be minted</Tab>
                            <Tab>Verified history</Tab>
                            <Tab>All NFTs</Tab>
                        </TabList>

                            <TabPanels className="card__grid">
                        
                                <TabPanel>
                                    
                                        {loading && <Loading/>}
                                        { !loading && <>
                                            <NftCards key="created" data={items} dataRef={dataRef} onMore={nextData("nft")} hasMore={hasMore}/>
                                        </>}
                                     
                                    
                                </TabPanel>
                                <TabPanel>

                                   
                                        {loading && <Loading/>}
                                        { !loading && <>
                                            <NftCards key="minted" data={items} dataRef={dataRef} onMore={nextData("nft_toMint")} setData={props.setData} onId={props.onId} onPage={props.onPage} hasMore={hasMore}/>
                                        </>}

                                        
                                </TabPanel>
                                <TabPanel>
                                   

                                        {loading && <Loading/>}
                                        { !loading && <>
                                            <NftCards key="verified" data={items} dataRef={dataRef} onMore={nextData("verify")} hasMore={hasMore}/>
                                        </>}  

                                </TabPanel>
                                <TabPanel>

                                    
                                        {loading && <Loading/>}
                                        { !loading && <>
                                            <NftCards key="allNft" data={items} dataRef={dataRef} onMore={nextData("nftAll")} hasMore={hasMore} />
                                        </>}


                                </TabPanel>
                            </TabPanels> 
                        </Tabs>
                    </fieldset>
                </div>
            
        </div>
    );
};

export default Dashboard;
