import { useState } from "react";
//import { Link } from "react-router-dom";
import Link from '../Link'
import logo from "../../img/avatar.svg";
import logo_mynextID from "../../img/logoMynextNFT-pro.svg";
import {SidebarData, SidebarUser } from "./SidebarData";
import { useNavigate } from "react-router-dom";

let serverUrl = window.location.origin;

interface Sidebar {
    onPage: string,
    entity: any,
    onPageChange: (current: string) => void,
    refresh: (params: any) => any;
}

let pathname = window.location.pathname;
if(pathname === "" ) {
    pathname = "/";
}


const Sidebar = (props:Sidebar) => {

    // Highlight navigation
    const navigate = useNavigate();
    const name = props.entity["name"];
    const email = props.entity["email"];
    let brand = "";

    if(props.entity.hasOwnProperty("metadata")){
        brand = props.entity["metadata"]["organization"];
    }

    return (
        <div className= "" id="sidebar">
            <div className="sidebar__title">
                <div className="sidebar__img">
                    <div id="logoIcon"><img src={logo_mynextID} alt="logo" className="clickable" onClick={ () => { props.onPageChange("/protect");navigate("/protect")}}/></div>
                </div>
                <i
                className="fa fa-times"
                id="sidebarIcon"
                aria-hidden="true"
                ></i>
            </div>

            <div className="sidebar__profile">
                <div className="sidebar__user">
                    <div id="user__title">
                        <h1>{name}</h1>
                        <p>{email}</p>
                        <p></p>
                        <p>Brand: {brand}</p>
                    </div>
                    {/*<div id="user__profile"><img src={logo} alt="logo" /></div>*/}
                </div>

                {props.entity.manager ?
                    <ul className='SidebarList'>
                    {SidebarUser.map((valUser,keyUser) => {
                        if(!props.entity.manager && valUser.title === "Manager"){
                            return  (<li key="0x0"> </li>)
                        }

                        return  (
                            
                            <li key={keyUser} className="row"  id={props.onPage == valUser.link ? "active" : ""}>
                                <Link to={valUser.link} onClick={() => { props.onPageChange(valUser.link); }}>
                                    <div id='title'>{valUser.title}</div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                :<></>}
                
            </div>


            <div className="sidebar__menu">
                <ul className='SidebarList'>
                    {SidebarData.map((val,key) => {
                        return  (
                            <li key={key} className="row"  id={props.onPage == val.link ? "active" : ""}>
                                <Link to={val.link} onClick={() => { props.onPageChange(val.link);props.refresh(false);
                                  
                                }}>
                                    <div id='title'>{val.title}</div>
                                </Link>
                            </li>
                        )
                    })}

                </ul>

                <ul className='SidebarList'>
                  
                    <li key={"Logout"} className="row"  id={props.onPage == "logout" ? "active" : ""}>
                        <Link to={"/logout"} onClick={() => { props.onPageChange("/logout");
                            
                        }}>
                            <div id='title' className="sidebar_logout">{"Logout"}</div>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="visual-footer">© 2022. MynextID. All Rights Reserved  |<span className="clickable" onClick={() => {navigate("/terms")}}> Terms of service </span> |<span className="clickable" onClick={() => {navigate("/privacy")}}>  Privacy policy</span></div>
        </div>
    );
};

export default Sidebar;