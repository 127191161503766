import React, {useState}  from "react";

import ProtectText from "../utils/protectText";
import FooterText from "../Footer/FooterText";
import LoginButton from "../Auth0/Login";

export default function ProPage() {


  return (
    
    <section className="content-wrap">
      <div className="col-visual col-visual-pro w-50">
        <div className="visual-wrap">
        Elevate your NFT game with MyNextNFT PRO
        </div>
        <FooterText/>
      </div>


      <div className="col-content w-50">
        <div className="cards-wrap contain-form">
          
          
          <div className="details-pro-section">
          {/* <div className="heading-title">
                MyNextNFT Pro
              </div> */}
              

            <p className="paragraph_section">
            {/* <strong>Elevate your NFT game and protect your original art with MyNextNFT Pro.</strong><br/><br/> */}
            MyNextNFT PRO allows you to prove your authorship, create multiple series of NFTs,
            and gain a better overview of the art you're selling.
            Sing up now to experience exciting additional features!
            </p>
          </div>

          <div className="detailss-pro-section">
                <LoginButton/>
            </div>

        </div>
      </div>
    </section>
  );
};
