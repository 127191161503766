import { propNames } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Auth0/Loading"
import HistoryTable from './HistoryTable';
import { Store } from 'react-notifications-component'


interface HistoryProps {
    setData: (data: object) => void
    setTimestampId: (timestampId: string) => void
}

export default function History(props: HistoryProps) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('/v1/api/history', {
            method: "POST",
            body: JSON.stringify({
                req_type: 'nft'
            })
        }).then(async (resp) => {
            if(!resp.ok) {
                let data = await resp.text();
                return Promise.reject(data);
            } else {
                let data = await resp.json();
                setData(data);
                setLoading(false);
            }
        }).catch((reason) => {
            setLoading(false);
            Store.addNotification({
                title: "Error",
                message: reason.toString(),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true,
                    pauseOnHover: true,
                }
            })
        })
    }, [loading])

    if(loading) {
        return (<Loading/>)
    } else {
        return <HistoryTable setData={props.setData} setTimestampId={props.setTimestampId} data={data} />
    }
}