import { useNavigate } from "react-router-dom";
import crypto from "crypto-js"
import { on } from "stream";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck} from '@fortawesome/free-solid-svg-icons'
import {JWS} from "../signer/jws"
import InfiniteScroll from "../InfiniteScroll";
import { useEffect, useState } from "react";

const defaultImage = require ("../../img/unavailable.png");

interface CardData {
    data: any[],
    dataRef: string,
    onMore: () => void
    onId?: (id: string, onId: string, processing: boolean) => void
    setData?: (data: object) => void
    onPage?: (data: string) => void
    hasMore:boolean
}


const NftCards = (props:CardData) => {

    const navigate = useNavigate();

    if(props.data === null || props.data.length === 0){
        return (
            <div>No data available</div>
        )
    }

    const nextData = () => {
        props.onMore()
    }


    const renderData = (): JSX.Element[] => {
        let keyCounter = 0;
        const out = props.data.map((el) => {
            keyCounter ++;
                let onClick = () => {
                    window.open(window.location.origin + '/verify/' + el.nftId, '_blank');
                }


                // Check if author is avaiable 
                let auth_data = "";

                if(el.hasOwnProperty("authorship")){
                    if(el["authorship"] !== ""){
                        let jws = new JWS(el["authorship"] as string);
                        auth_data = jws.payload.statement.author;
                    }
                }
                

                if(props.dataRef === "nft"){
                    return(<div className="card clickable" key={el.name + keyCounter + el.nftId} onClick={onClick}>

                            <div className="card_inner" >
                                <p className="text-primary-p " >{el.name}</p>
                                {auth_data !== "" ? 
                                    <p className="text-primary-p-author ">{auth_data}</p>
                                :<></>}
                            </div>

                            {el.authorship !== "" && 
                                <div className="bubble auth" ><FontAwesomeIcon icon={faCircleCheck} size='2x' /></div>
                            }
                            <div >
                                <img src={(el.image) ? el.image: defaultImage}></img>
                            </div>
                    
                        </div>)
                }
                else if(props.dataRef === "nft_toMint"){
                    return(
                        <div className="card" key={el.name + keyCounter+ el.nftId}>
                        
                            <div className="card_inner_mint" >
                                <p className="text-primary-p " >{el.name}</p>
                                {auth_data !== "" ? 
                                    <p className="text-primary-p-author " >{auth_data}</p>
                                :<></>}
                            </div>

                            {el.authorship !== "" && 
                                <div className="bubble auth"><FontAwesomeIcon icon={faCircleCheck} size='2x' /></div>
                            }
                        <div >
                            <img src={(el.image) ? el.image: defaultImage} ></img>
                            </div>

                            <button className="mint__card" onClick={() => {
                                let asId = "";
                                if(el.authorship) {
                                    asId = crypto.SHA256(el.authorship).toString();
                                }
                                props.setData?.({"nft_id":el.nftId, "author_address":el.account, "asset_id": el.assetId, "authorship_statement": el.authorship , "SN":el.SN})
                                props.onId?.(el.assetId,asId,false)
                                // update onPage
                                if(props.onPage) {
                                    props.onPage("/mint");
                                }
                                navigate('/mint');
                            }}>
                            Mint now!
                            </button>
                    
                        </div>
                    )
                }
                else if(props.dataRef === "verify"){
                    return(
                        <div className="card clickable" key={el.name + keyCounter+ el.nftId} onClick={onClick}>

                            <div className="card_inner"  >
                                <p className="text-primary-p " >{el.name}</p>
                                <p className="text-primary-p-author " >Brand: {el.brand}</p>
                                {auth_data !== "" ? 
                                    <p className="text-primary-p-author " >Author: {auth_data}</p>
                                :<></>}
                            </div>

                            {el.authorship !== "" && 
                                <div className="bubble auth" ><FontAwesomeIcon icon={faCircleCheck} size='2x' /></div>
                            }
                        <div>
                            <img src={(el.image) ? el.image: defaultImage}></img>
                            </div>
                    
                        </div>
                    )
                }
                else if(props.dataRef === "nftAll"){
                    return(
                        <div className="card clickable" key={el.name + keyCounter+ el.nftId} onClick={onClick}>

                            <div className="card_inner"  >
                                <p className="text-primary-p " >{el.name}</p>
                                <p className="text-primary-p-author " >Brand: {el.brand}</p>
                                {auth_data !== "" ? 
                                    <p className="text-primary-p-author " >Author: {auth_data}</p>
                                :<></>}
                            </div>

                            {el.authorship !== "" && 
                                <div className="bubble auth" ><FontAwesomeIcon icon={faCircleCheck} size='2x' /></div>
                            }
                        <div>
                            <img src={(el.image) ? el.image: defaultImage}></img>
                            </div>

                        </div>
                    )
                }
            return(<span key={keyCounter} ></span>)
        })
        return out;
    }

    return (
    
        <InfiniteScroll
            onMore={nextData}
            hasMore={props.hasMore}
            loading={<h4 >Loading...</h4>}
        >
        <div key={"dashboard_cards_" + props.dataRef} className="dashboard__cards">
            { renderData() }
        </div>
        </InfiniteScroll>  
    );
   
};

export default NftCards;
