import React, {useState}  from "react";

import ProtectText from "../utils/protectText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa1, fa2, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-regular-svg-icons'
import FooterText from "../Footer/FooterText";
import LoginButton from "../Auth0/Login";
import { propNames } from "@chakra-ui/react";
import { ClassNames } from "@emotion/react";
import config from "../../config.js";
const defaultImage = require ("../../img/unavailable.png");


interface MintPageFinishProps {
  trans_res: any,
  data: any,
  entity: any,
}

export default function MintPageFinish(props: MintPageFinishProps) {

  let transaction_link = config.etherscan_link + props.trans_res.transactionHash;
  let isPro = props.entity.hasOwnProperty("name");

  console.log(props.data);
  //console.log(isPro);

    return (  
        <>
        {!isPro ? 
            <section className="content-wrap light-bg">

            <div className="col-visual col-visual-mint w-50">
                <ProtectText/>
                <FooterText/>
            </div>

            <div className="col-content w-50">
                <div className="cards-wrap contain-form">

                    <div className="heading">
                        <div className="heading-step">Step 2 of 2</div>
                        <div className="heading-title">Your NFT is minted</div>
                        <div className="heading-description">
                            Great job! You've created an NFT with proof of authorship, 
                            which means you can always prove that you're the original art creator,
                            and anyone can easily verify this information.
                        </div>

                        { Object.keys(props.entity).length === 0 ? 
                            <>
                                <p className="pro-link">
                                    <br/>Elevate your NFT game with MyNextNFT PRO! Sign up now to access
                                    exciting additional features and protect your art with proof of authorship.
                                    Don't wait – unleash your creativity today!<br/><br/>
                                </p>

                                
                                <div >
                                    <LoginButton/>
                                </div>
                            </>
                            :<></>
                        }

                        <div className="cards cards-2">
                            <div >
                                <div id="mint-warning">
                                    <div className="w-20" style={{marginRight:"2rem"}}>
                                        <div className="nft-prev">
                                            <p>Your NFT</p>
                                            <img id="mint-img"  src={(props.data.image) ? props.data.image: defaultImage } ></img>
                                        </div>
                                        
                                    </div>

                                    <div className="w-20">
                                        <div className="flex">
                                            <label className="mint-label">ASSET ID </label> 
                                            <p className="word-wrap compressed"> &nbsp;{compressString(props.data.asset_id)} <span><FontAwesomeIcon icon={faClone}   className="copyNFT"  onClick={() =>  navigator.clipboard.writeText(props.data.asset_id)} /></span></p>
                                        </div>
                                        <div className="flex">
                                            <label className="mint-label">NFT ID</label> 
                                            <p className="word-wrap compressed"> &nbsp;{compressString(props.data.nft_id)}<span> <FontAwesomeIcon icon={faClone}  className="copyNFT"  onClick={() =>  navigator.clipboard.writeText(props.data.nft_id)} /></span></p>
                                        </div>
                                        {props.data.SN !== "" ? 
                                            <div className="flex">
                                                <label className="mint-label">SERIAL NUMBER</label> 
                                                <p className="word-wrap compressed"> &nbsp;{(props.data.SN)}<span> <FontAwesomeIcon icon={faClone}  className="copyNFT"  onClick={() =>  navigator.clipboard.writeText(props.data.nft_id)} /></span></p>
                                            </div>
                                        :<></>}
                                        <div className="flex"> 
                                            Check your NFT on &nbsp;<a className="link-text" href={transaction_link} target="_blank"> etherscan</a>.
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>    
        
                        </div>
                    </div>
                </div>
            </div>     
        </section>

        :
        <div className="grid-content-left main__container" >
            <div className="main__title">
                <div className="main__greeting">
                    <div className="heading-step">Step 2 of 2</div>
                    <div className="heading-title">Your NFT is minted</div>
                    <div className="heading-description">
                            Great job! You've created an NFT with proof of authorship, 
                            which means you can always prove that you're the original art creator,
                            and anyone can easily verify this information.
                    </div>
                </div>
            </div>

            <div className="cards cards-2">
                <div >
                    <div id="mint-warning">
                        <div className="w-20" style={{marginRight:"2rem"}}>
                            <div className="nft-prev">
                                <p>Your NFT</p>
                                <img id="mint-img"  src={(props.data.image) ? props.data.image: defaultImage } ></img>
                            </div>
                            
                        </div>

                        <div className="w-20">
                            <div className="flex">
                                <label className="mint-label">ASSET ID </label> 
                                <p className="word-wrap compressed"> &nbsp;{compressString(props.data.asset_id)} <span><FontAwesomeIcon icon={faClone}   className="copyNFT"  onClick={() =>  navigator.clipboard.writeText(props.data.asset_id)} /></span></p>
                            </div>
                            <div className="flex">
                                <label className="mint-label">NFT ID</label> 
                                <p className="word-wrap compressed"> &nbsp;{compressString(props.data.nft_id)}<span> <FontAwesomeIcon icon={faClone}  className="copyNFT"  onClick={() =>  navigator.clipboard.writeText(props.data.nft_id)} /></span></p>
                            </div>
                            {props.data.SN !== "" ? 
                                <div className="flex">
                                    <label className="mint-label">SERIAL NUMBER</label> 
                                    <p className="word-wrap compressed"> &nbsp;{(props.data.SN)}<span> <FontAwesomeIcon icon={faClone}  className="copyNFT"  onClick={() =>  navigator.clipboard.writeText(props.data.nft_id)} /></span></p>
                                </div>
                            :<></>}
                            <div className="flex"> 
                                Check your NFT on &nbsp;<a className="link-text" href={transaction_link} target="_blank"> etherscan</a>.
                            </div>
                        </div>
                        
                    </div>
                </div>    
            </div>
        </div>
        
        }
        </>
        
    );
};



function compressString(text: string) {
    let result = text;

    if(text.length > 6){
        var firstFour = text.substring(0,4);
        var lastFour = text.substr(text.length - 4);

        result = firstFour + "..." + lastFour;
    }
    
    return result;
}
