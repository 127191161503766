
import { useEffect, useState } from "react";
import { Store } from 'react-notifications-component'

interface NotificationProps {
    entity: any
    onMsgNum: (notifNum: number) => void
    onMsg: (data: any) => void
}

// Testing push
const Notification = (props: NotificationProps) => {

    // Get manager pending users
    const fetchManagerReq = () => {

        fetch('/managerData?type=pending' , {
            method: "GET",
        })
        .then(async (resp) => {
            if(!resp.ok) {
                let data = await resp.text();
                return Promise.reject(data);
            } else {
                let data = await resp.json();
                if(data.data !== null){
                    props.onMsgNum(data.data.length);
                }else{
                    props.onMsgNum(0);
                }
                
            }
        }).catch((reason) => {
            Store.addNotification({
                title: "Error",
                message: reason.toString(),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true,
                    pauseOnHover: true,
                }
            })
        })
    };

    // Update issuance requests display info
    const updateNotification = (data:any) => {
        const parsedData = JSON.parse(data);
        props.onMsg(parsedData);
        fetchManagerReq();
    };

    // Update user
    const updateUser = (data:any) => {
        window.location.reload();
    };

    //Calls for all issuance data and call function to update the website
    useEffect(() => {
        if(props.entity.manager){
            fetchManagerReq();
            const eventSource = new EventSource(`/notify`);
            eventSource.onmessage = (e) => updateNotification(e.data);
            return () => {
                eventSource.close();
            };

        }else if(props.entity.pending){
            const eventSource = new EventSource(`/notify`);
            eventSource.onmessage = (e) => updateUser(e.data);
            return () => {
                eventSource.close();
            };
        }
        
    }, []);

    return(<> </>)
};

export default Notification;
