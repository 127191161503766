import React, {useEffect, createElement, useState} from 'react'

interface InfiniteScrollProps {
    hasMore: boolean
    onMore: () => void
    children: React.ReactNode
    loading?: React.ReactElement
    onDone?: React.ReactElement
}

export default function InfiniteScroll(props: InfiniteScrollProps) {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log("useEffect MAIN");
        const main = document.getElementsByTagName("main")[0];

        main.addEventListener('scroll', (event) => {
            if (Math.abs(main.scrollHeight - main.scrollTop - main.clientHeight) < 1 && !loading) {
                setLoading(true);
            }
        })

        if(main.scrollHeight <= main.clientHeight) {
            // no scroll bar yet, get more data
            setLoading(true);
        }

        const resizeListener = () => {
            if(main.scrollHeight <= main.clientHeight) {
                // no scroll bar yet, get more data
                setLoading(true);
            }
        }

        window.addEventListener('resize', resizeListener)

        return function() {
            main.removeEventListener('scroll', () => {})
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    useEffect(() => {
        if(loading) {
            if(props.hasMore) {
                props.onMore()
                setLoading(false);
            }
        } else {
            const main = document.getElementsByTagName("main")[0];
            if(main.scrollHeight <= main.clientHeight) {
                // no scroll bar yet, get more data
                setLoading(true);
            }
        }
    }, [loading])

    return <>
        {props.children}
        {(loading && props.hasMore) ? props.loading : <></>}
        {(loading && !props.hasMore) ? props.onDone : <></>}
    </>;
}