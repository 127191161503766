import VerifyForm from "../Verify/VerifyForm";
import OrionNebulaRing from "../Jewelry/Orion-Nebula-ring";
import Cufflink from "../Jewelry/Cufflink";
import { useParams, useSearchParams } from "react-router-dom";
import VerifyResult from "../Verify/VerifyResult";
import { ClassNames } from "@emotion/react";
import FooterText from "../Footer/FooterText";


interface VerifyProps{
    entity:any
}

export default function Verify(props:VerifyProps) {

  const data = useParams();

  if(data.q) {
      if(data.q === ("Orion-Nebula-ring")){
        return(
          <OrionNebulaRing/>
        );

      }else if((data.q) === ("Cufflink")){
        return (
          <Cufflink/>
        );
      }

      else{
        return(
            <VerifyResult data={data.q} entity={props.entity}/>
        )
      }
      
  } else {
    return (
    
        <section className="content-wrap">
            <div className="col-visual col-visual-verify w-50">
                <div className="visual-wrap">
                <div className="visual-paragraph">
                    Verify the authenticity of NFTs
                </div>
                </div>
                <FooterText />
            </div>
    
    
            <div className="col-content w-50">
                <div className="cards-wrap contain-form">
        
                    <div className="cards">
    
                    <div className="heading">
                        
                        {/* <div className="heading-title">Verify your NFTs</div> */}
                        
                        <div className="heading-description">
                            Don't risk selling or buying a fake NFT!
                            With MyNextNFT, you can easily verify proof of authorship that
                            traces the NFT back to the original creator, no matter who the current owner is.
                            As a buyer, you can ensure the authenticity of your purchase, and as a seller,
                            you can provide added protection for buyers.
                        </div>
                    </div>
    
                    <VerifyForm pageMode="basic"/>
                    
                    </div>
                
                </div>
            </div>
        </section>
      );
    }
  };