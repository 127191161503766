import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell} from '@fortawesome/free-solid-svg-icons'
import { faBars} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

interface Navbar {
    sidebarOpen: any,
    openSidebar: Function,
}

const NavbarOnboarding= (props: Navbar) => {
  return (<>
    <nav className="navbar">
      <div className="nav_icon" >
      </div>
      <div className="navbar__left" >
       
      </div>
      <div className="navbar__right">
        <Link to={"./logout"} >Logout</Link>
      </div>
    </nav>
    <div className="cr cr-top cr-right cr-sticky cr-red">BETA</div>
  </>);
};

export default NavbarOnboarding;

