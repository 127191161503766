import React from "react";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Tag,
  } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const defaultImage = require ("../../img/unavailable.png");

interface HistoryEntry {
    image?: any
    name: string
    type: string
    isMinted: boolean

    assetId: string
    account: string
    nftId: string
}

interface HistoryProps {
    setData: (data: object) => void
    setTimestampId: (timestampId: string) => void
    data: HistoryEntry[]
}

export default function HistoryTable(props: HistoryProps) {

    let count = 0;
    const navigate = useNavigate();
   
    //console.log(props.data);
    return (
        <TableContainer className="table-history">
            <Table size='md' className="table-tiny" border="0px;">
                <Thead>
                    <Tr key="Head">
                        <Th w="10px"></Th>
                        <Th w="10px">Thumbnail</Th>
                        <Th>Name</Th>
                        <Th>Type</Th>
                        <Th w="150px">Action</Th>
                    </Tr>
                </Thead>
                <Tbody>

                    {props.data === null && 
                        <Tr key="NoEntriesAvailable">
                            <Td></Td><Td></Td><Td>no entries available</Td><Td></Td><Td></Td>
                        </Tr> 
                    }
               
                    {props.data !== null && props.data.map((entry) => {
                        count ++;
                        let action = <></>
                        let onClick = () => {
                            window.open(window.location.origin + '/verify/' + entry.nftId, '_blank');
                            //window.location.href = '/verify/' + entry.nftId;
                        }
                        if(!entry.isMinted) {
                            action = <Tag size='lg' variant='solid' colorScheme='cyan' style={{backgroundColor: "#be3455"}} onClick={() => {
                                props.setData({"nft_id":entry.nftId, "author_address":entry.account, "asset_id": entry.assetId })
                                props.setTimestampId(entry.assetId)
                                navigate('/mint');
                            }}>
                                Mint now!
                            </Tag>
                            onClick = () => {}
                        }
                        return (
                            <Tr key={entry.name}>
                                <Td style={{ color: "rgb(112, 122, 131)"}}>{count}</Td>
                                <Td onClick={onClick}><img src={(entry.image) ? entry.image: defaultImage} className="table-image" ></img></Td>
                                <Td onClick={onClick}>{entry.name}</Td>
                                <Td onClick={onClick}>{entry.type}</Td>
                                <Td>{action}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    )
}