import React from "react";
import { Spinner } from "@chakra-ui/react";

function Loading(){
  return (
    <Spinner className="spinner"
      thickness='4px'
      speed='0.60s'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'
    />
  )
}

export default Loading;