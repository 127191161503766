import React, {useEffect, useState} from "react";
import FooterText from "../Footer/FooterText";
import VerifyForm from "../Verify/VerifyForm";
import Loading from "../Auth0/Loading";
import { Store } from 'react-notifications-component'

export default function VerifyPro() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataNFT, setDataNFT] = useState([]);
    const [selected, setSelected] = useState('brand');
    const [search, setSearch] = useState('');
    const [emptysearch, setEmptysearch] = useState(true);

    useEffect(() => {
        fetch('/v1/api/history', {
            method: "POST",
            body: JSON.stringify({
                req_type: 'verify'
            })
        }).then(async (resp) => {
            if(!resp.ok) {
                let data = await resp.text();
                return Promise.reject(data);
            } else {
                let data = await resp.json();
                setData(data);
                setLoading(false);
            }
        }).catch((reason) => {
            
            Store.addNotification({
                title: "Error",
                message: reason.toString(),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true,
                    pauseOnHover: true,
                }
            })
            setLoading(false);
        })
    }, [])

    const handleChange = (event:any) => {
        setSelected(event.target.value);
    };

    const handleChangeInput = (event:any) => {
        setSearch(event.target.value);
        if(event.target.value !== ''){
            setEmptysearch(false);
        }else{
            setEmptysearch(true);  
        }
    };

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        let obj = {
            req_type: 'nftAll',
            search_brand:'',
            search_name: '',
            search_address: '',
            per_page: 0,
        }

        if(selected === "brand"){
            obj["search_brand"] = search;
        }
        else if(selected === "NFT name"){
            obj["search_name"] = search;
        }else {
            obj["search_address"] = search;
        }


        fetch('/v1/api/history', {
            method: "POST",
            body: JSON.stringify(obj)
        }).then(async (resp) => {
            if(!resp.ok) {
                let dataNFT = await resp.text();
                return Promise.reject(dataNFT);
            } else {
                let dataNFT = await resp.json();
                setDataNFT(dataNFT);
                setEmptysearch(false);  
            }
        }).catch((reason) => {
            
            Store.addNotification({
                title: "Error",
                message: reason.toString(),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true,
                    pauseOnHover: true,
                }
            })
            setEmptysearch(false);  
        })
	//Form submission happens here
    }

    if(loading) {
        return (<Loading/>)
    } else {
        return (<>

            <div className="mobile-pro">
                <h1>Verify the authenticity of your NFT</h1>
            </div>

            <div className="grid-content-left main__container" >
                <div className="main__title">
                    <div className="main__greeting">
                        {/* <h1>Verify</h1> */}
                        <p>Don't risk selling or buying a fake NFT! With MyNextNFT, you can easily verify proof of authorship that traces the NFT back to the original creator, no matter who the current owner is. As a buyer, you can ensure the authenticity of your purchase, and as a seller, you can provide added protection for buyers.</p>
                        
                    </div>
                </div>

                <div className=" col-content-verify">
                    <div className="cards-wrap contain-form" style={{minWidth:"500px"}}>
            
                        <div className="cards cards-3">
                            <VerifyForm pageMode="pro"/>
                        </div>

                    </div>
                </div>
            </div>    
          </>);
    }
}
