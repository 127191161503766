import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import crypto from "crypto-js"
import useWallet from "../../../walletconnect/hook";
import { ABI } from "../../../contract_utils/contract_abi";
import Web3 from "web3";
import { ethers } from "ethers";
import { sign } from "crypto";
import { GetWeb3Provider, TxHashObject } from "../../../contract_utils/web3_functions";

import { useNavigate } from "react-router-dom";

// Alert notification
import { Store } from 'react-notifications-component';
import { Buffer } from 'buffer';
import {JWS} from '../../signer/jws';
import {getMetamaskSigner} from '../../signer/metamask';
import ReactGA from 'react-ga4';
import config from "../../../config.js";

type FormInputs = {
  file: FileList,
};

// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);

interface UploadFileProps {
  onId: (id: string, as_id: string, processing: boolean) => void
  basicData: (data:object) => void
  entity: any
  onPage?:(data:string) => void
}

let serverUrl = window.location.origin;


function generateNonce(length:any) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function readFile(file: File | null, onFile: (content: string) => void) {
    // READ FILE CONTENT
    if(file) {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file as Blob);
        fileReader.onload = async (e) => {
            const fileContent: string | undefined = fileReader.result as string;
            if(fileContent) {
                onFile(fileContent);
            } else {
                onFile("");
            }
        }
    } else {
        onFile("");
    } 
}


export const UploadFilePRO = (props: UploadFileProps) => {

    console.log("Entity",props.entity);

    // Form elements to timestamp
    const initialValues = {
        mintName: "",
        mintDesc:"",
        mintUrl:"",
        mintAnimationUrl:"",
        mintYoutubeUrl:"",
        mintAuthor:props.entity.metadata.name,
        mintBrand:props.entity.name,
        mintBrandDesc:"",
        mintDate:"",
        mintAuthorDesc:"",
        mintCertUrl:"",
        mintBrandUrl:"",
    }

    const [assetID,setAssetID] = useState('');
    const {active, account, library, connector, activate, deactivate, chainId} = useWallet();
    const [hasConnected, setHasConnected] = useState(false);
    const [fileContents, setFileContents] = useState("");
    const [fileContentsSN, setFileContentsSN] = useState("");
    const [show, setShow] = useState(false)
    const [fileName, setFileName]= useState("");
    const [fileNameSN, setFileNameSN]= useState("");

    //------------------------------------- Disable parts of form ------------------------------------

    const [emptyUpload, setEmptyUpload] = useState(true);
    const entity = props.entity
    //let isPro = props.entity.hasOwnProperty("name");
    let isApproved =  false;

    if(props.entity.hasOwnProperty("approved")){
        isApproved = props.entity["approved"];
    }

    // -------------------------------------- Form elements ------------------------------------------
    const [values, setValues] = useState(initialValues);

    const handleInputChange = (e:any) => {
        const { name, value } = e.target;

        //Desctructing to get values
        setValues({
          ...values,
          [name]: value,
        });

    };

    // SIGNING
    const resultBox = useRef();
    const [signatures, setSignatures] = useState<any[]>([]);

    const handleSign = async (msg_data:any) => {
       
        const sig = await signMessage({
            setError,
            message: msg_data
        });

        let proof = [...signatures, sig];
        if (sig) {
            setSignatures(proof);
        }

        return sig;

    };

    useEffect(() => {
        if(hasConnected) {
            CreateTimestamp(fileContents,fileContentsSN);
            setHasConnected(false);
        }
    }, [hasConnected])

    const connectMetamaskSimple = async (fileContent: string) => {
        try {
            await activate();
            // refresh this component
            setFileContents(fileContent);
            setHasConnected(true);
        } catch (ex) {
            console.log(ex);
        }
    };

    
    // Detection if user alredy uploaded file 
    var fileInput = document.getElementById('ts-assetID');
    if(fileInput !== null) {
        fileInput.addEventListener('change', function () {
        }, false);
    }



    // navigate for <Router> context, to change url
    const navigate = useNavigate();
    const {register, handleSubmit, setError, formState: {errors}} = useForm<FormInputs>();

    function onlyLettersAndNumbers(str:any) {
        if(str.indexOf(":") !== -1){
            let element = str.split(":")[1];
            return /^[A-Za-z0-9]*$/.test(element);
        }

        return /^[A-Za-z0-9]*$/.test(str);
    }

    const signMessage = async ({ setError, message }:any) => {
        try {
            //console.log({ message });
            if (!window.ethereum)
                throw new Error("No crypto wallet found. Please install it.");
        
            await window.ethereum.send("eth_requestAccounts");
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const signature = await signer.signMessage(JSON.stringify(message));
            const address = await signer.getAddress();
        
            return {
                message,
                signature,
                address
            };

        } catch (err:any) {
            console.log(err);
            Store.addNotification({
                title: "Error",
                message: err.message.toString(),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            })
        }
    };

    // Checking file / SN
    const onSubmitForm: SubmitHandler<FormInputs> = (data: FormInputs) => {

        /*
        let nft: File | null = null;
        let thumbnailNft: File | null = null;

        console.log(data.file);

        if(data.file.length > 0) {
            nft = data.file.item(0);
        }
        

        readFile(thumbnailNft, () => {
            
            readFile(nft, (nftFile) => {
                if(nftFile !== "") {
                    if(nft) {
                        if(nft.size > 2000000) {
                            setError("file", {type: "file_size", message: "File too large. File size must be under 2MB."});
                            return;
                        }
                    }
                    nftFile = Buffer.from(nftFile).toString('base64');
                } else {
                    if(assetID.length < 6){
                        setError("file", {type: "file_size", message: "Serial number  must contain at least six characters."});
                        return;
                    }
                    else if(!onlyLettersAndNumbers(assetID)){
                        setError("file", {type: "file_size", message: "Serial number  must contain only numbers and letters."});
                        return;
                    }
                }
                CreateTimestamp(nftFile);
            })
        })*/

        if(fileName !== ""){
            // VALIDATE FILE SIZE < 2MB

            if (data.file.item(0)!.size > 2000000) {
            setError("file", {type: "file_size", message: "File too large. File size must be under 2MB."});

            } else {
                // READ FILE CONTENT
                let fileReader = new FileReader();
                fileReader.readAsArrayBuffer(data.file.item(0) as Blob);
                fileReader.onload = async (e) => {
                    try {
                        const fileContent: string | undefined = fileReader.result as string;
                            if (!fileContent || fileContent === "") {
                                setError("file", { type: "metamask_error", message: "Missing file content." })
                                return
                            }
                            
                            let newfileContent = Buffer.from(fileContent).toString('base64');
                            CreateTimestamp(newfileContent,"");
                    } catch (e) {
                        console.log(e);
                        setError("file", {type: "file_content", message: "There is something wrong with file content. Try uploading other file."})
                    }
                }
            }
        }else{

            // Read file for thumbnail image if it was provided

            if(assetID.length < 6){
                setError("file", {type: "file_size", message: "Serial number  must contain at least six characters."})
            }
            else if(!onlyLettersAndNumbers(assetID)){
                setError("file", {type: "file_size", message: "Serial number  must contain only numbers and letters."})
            }else{
                if(fileNameSN !== ""){
                
                    // VALIDATE FILE SIZE < 2MB
                    if (data.file.item(0)!.size > 2000000) {
                    setError("file", {type: "file_size", message: "File too large. File size must be under 2MB."});
        
                    } else {
                        // READ FILE CONTENT
                        let fileReader = new FileReader();
                        fileReader.readAsArrayBuffer(data.file.item(0) as Blob);
                        fileReader.onload = async (e) => {
                            try {
                                const fileContentSN: string | undefined = fileReader.result as string;
                                    if (!fileContentSN || fileContentSN === "") {
                                        setError("file", { type: "metamask_error", message: "Missing file content." })
                                        return
                                    }

                                    let newfileContentSN = Buffer.from(fileContentSN).toString('base64');
                                    CreateTimestamp("",newfileContentSN);
                            } catch (e) {
                                console.log(e);
                                setError("file", {type: "file_content", message: "There is something wrong with file content. Try uploading other file."})
                            }
                        }
                    }
                }else{
                    CreateTimestamp("","");
                }
            }
        }
    }    

    // Timestamping
    async function CreateTimestamp(fileContent: string,fileContentSN: string): Promise<void> {

        let assetIDBrand = assetID;

        if(!web3 || !account){
            setFileContentsSN(fileContentSN);
            await connectMetamaskSimple(fileContent);
            return;
        } 


        let generated_hash;
        let typeOfData: string;

        // If asset id (serial number) is given, use it else generate it from file content
        let serialNumber = "";
    
        if(assetIDBrand !== ""){

            assetIDBrand = values.mintBrand+ ":" + assetID;
            serialNumber = assetIDBrand;

            typeOfData = "SN";
            console.log("Generating with serial number");
            let generated_hashA: crypto.lib.WordArray = crypto.SHA256(serialNumber);
            generated_hash = generated_hashA;

        } else{
            typeOfData = "file";
            console.log("Generating file content ",fileContent,typeof fileContent);
            let generated_hashB: crypto.lib.WordArray = crypto.SHA256(fileContent);
            generated_hash = generated_hashB;
        }
    
        var _alice = null;

        if (account !== null) {
            _alice = crypto.enc.Hex.parse(account.replace('0x', ''));
        } 
        
        if(_alice !== null){
            

            // Generated hash zamenjaj z assetId (poimenovanje)
            let assetId = generated_hash.toString();

            // Check if asset ID already exist
            let notUnique = false;
            let urlCheck = serverUrl + `/api/v1/exist?id=`+ assetId;
            const response = await fetch(urlCheck,{
                method:"GET",
                headers: {
                  accept: 'application.json',
                  'Content-Type': 'application/json'
                },
            });

            console.log("Response , ",response);
            // check for error response
            if (!response.ok ) {
                // get error message from body or default to response status
                console.log("Error in response code")
                const error = await response.text();
                throw new Error(error);

            }else{
                notUnique = await response.text() === "true";
                //const data1 = await response.text();
                if(notUnique){
                    console.log("ERROR MSG");
                    Store.addNotification({
                        title: "Error",
                        message: "Given NFT was already created !",
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                        })
                    throw new Error("Given NFT was already created !");
                }
            }

            //-----------------------------------------------------------------------//

            // Is asset id unique ?
            if(!notUnique){
                
                const statement: any = {
                    statement: {
                        "animation_url":"",
                        "youtube_url":"",
                        "certificate_url":values.mintCertUrl,
                        "brand_url":values.mintBrandUrl,
    
                        "author":values.mintAuthor,
                        "author_desc": values.mintAuthorDesc,
                        "brand":values.mintBrand,
                        "brand_desc":values.mintBrandDesc,
                        "date": values.mintDate,
                    },
                    statement_version: 1,
                    //nonce: generateNonce(8),
                }
    
                let metamaskSigner = await getMetamaskSigner(window.ethereum)
                        
                //---------------------------------------------------------------------------------------------------------------------
                // Generate msg for sign VC via MetaMask
                //---------------------------------------------------------------------------------------------------------------------
                let jws = new JWS(statement);
                await jws.sign(metamaskSigner);
                
                let jws_hash: crypto.lib.WordArray =  crypto.SHA256(jws.encode());
                let asId: string = jws_hash.toString();
                var _nid1 = generated_hash.concat(jws_hash);
                var _nidsha = crypto.SHA256(_nid1);
                var _nid = _alice.concat(_nidsha);
                let _nftId = crypto.SHA256(_nid);
    
                // Pretvoris v obliko za SC
                let nftId: any = web3.eth.abi.encodeParameter('bytes32', '0x' + _nftId.toString());
    
                props.onId(assetId, asId,  true);
    
                const smartContract = new web3.eth.Contract(ABI,  config.SmartContract_ADDRESS);
            
                // let nftId: any = web3.eth.abi.encodeParameter('uint256', '0x' + _nftId.toString());
                //smartContract.methods.ownerOf(nftId).send({ from: account }).then((res: TxHashObject) => {
    
                smartContract.methods.timestamp(nftId).send({ from: account }).then((res: TxHashObject) => {
                    if (!res.status) {
                        setError("file", { type: "metamask_error", message: "Error when sending transaction" })
                        props.onId(assetId, asId, false);
                        return;
                    } else {
    
                        //Successfully timestamped - time to save data 
                        let formData = {
                            "asset_id": assetId,
                            "address": account,
                            "file":fileContent,
                            "file_name": fileName,
                            "tx_hash_ts": res.transactionHash,
                            "type": typeOfData,
                            "serial_num": serialNumber,
                            "pro":true,
                            "nft_id" : nftId,
                            "chain_id": chainId,
    
                            "name": values.mintName,
                            "description": values.mintDesc,
                            "external_url": values.mintUrl,
                            "image": config.website_link+"/v1/image/"+nftId,
    
                            "thumbnail": fileContentSN,
                            "thumbnail_name": fileNameSN,
    
                            "entity":props.entity["name"],
    
                            "req_type": "saveTS",
    
                            "authorship": jws.encode(),
                        }
    
                        var url = serverUrl + '/saveNft';
    
                        // fetch data (do REST request)
                        fetch(url,{
                            method:"POST",
                            headers: {
                              accept: 'application.json',
                              'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(formData)
                        })
                        .then(async response => {
                    
                            // check for error response
                            if (!response.ok ) {
                                // get error message from body or default to response status
                                console.log("Error in response code")
                                const error = await response.text();
                                return Promise.reject(error);
    
                            }else{
                    
                                const data1 = await response.text();
                                const data = JSON.parse(data1);
                        
                                // data recieved, set issued to data, and stop loading
                                //that.setState({issued: data1, loading: false, isDisabled:false})
                                
                                if(data.success) {
    
                                    // GA4
                                    ReactGA.event({
                                        action: 'protect-pro_action',
                                        category: 'protect-pro_category',
                                    })

                                    // Continue flow to mint stage 
                                    props.onId(assetId, asId, false);
                                    props.basicData({"nft_id":nftId, "author_address":account, "asset_id": assetId, "authorship_statement": jws.encode(), "SN":assetIDBrand })
    
                                    if(props.onPage){
                                       props.onPage("/mint"); 
                                    }
                                    
                                    navigate("/mint");
                                } else {
                                    throw new Error(data1);
                                }
    
                            }
                        })
                        .catch(error => {
                            console.error("Error in API Issue: ", error);
                            props.onId(assetId, asId, false);
                            //that.state.callback("Error", error.message + ".",true);
                        });
    
                    }
                }).catch((err: any) => {
                    console.log(err);
                    props.onId("", "", false);
    
                    Store.addNotification({
                    title: "Error",
                    message: "Transaction rejected",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                    })
                });
            }
        
        }
    }     

    useEffect(() => {
      if(errors.file) {
        Store.addNotification({
          title: "Error",
          message: errors.file.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 4000,
            onScreen: true
          }
        })
      }
    }, [errors.file]);

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="form">
            <div className="protect__cards">
        
                <div className="cards">
                    <fieldset >       

                        <div >
                            <div className="btn-container" style={{margin: "auto", display:"inherit"}}>
                                <label className="switch btn-color-mode-switch">
                                <input  type="checkbox" name="color_mode" id="color_mode" value="1"  onClick={(e) => {setShow(show => !show);   setFileName(""); setEmptyUpload(true);setAssetID(""); setFileNameSN("");}} />
                                <label htmlFor="color_mode" data-on="Physical" data-off="Digital" className="btn-color-mode-switch-inner"></label>
                                </label>
                            </div>
                        </div>

                        { /*<label><span className="requiredMark">*</span> Required fields</label>*/ }

                        {!show && <>

                            <label htmlFor="imagesUpload" className="drop-container bottom">
                                <span className="drop-title"> Drag your art or click to upload <span className="requiredMark">*</span></span>

                                <input  id="imagesUpload"
                                        {...register("file", {
                                            required: "You must upload a file."
                                        })}
                                        type="file"
                                        onChange={(e:any) => {

                                            if(e.target.files[0] !== undefined){
                                                setFileName(e.target.files[0].name);
                                                setEmptyUpload(false);
                                            }else{
                                                setFileName("");
                                                setEmptyUpload(true);
                                            }
                                        }}
                                    />
                                <p id="fileName">{fileName}</p>
                            </label>
                        </>
                        }

                        {show && 
                            <div>
                                <label id="SNLabelPro"><span className="requiredMark">*</span> Serial number</label>
                                <div className="input-group prefix" style={{width:"100%"}}>
                                    <span className="input-group-addon">{values.mintBrand}:</span>
                                    <input type="text" name="asset" className="verify-form" id="ts-assetID" value={assetID} onChange={event => {

                                        if(event.target.value !== ""){
                                        setAssetID( event.target.value)
                                        setEmptyUpload(false);
                                        }else{
                                        setEmptyUpload(true);
                                        setAssetID("")
                                        }

                                    }} /> 
                                </div>

                                <label htmlFor="imagesUpload" className="drop-container bottom">
                                    <span className="drop-title">Drag your art or click to upload (max. file size 2MB)</span>

                                    <input  id="imagesUpload"
                                            {...register("file", { required: false})}
                                            type="file"
                                            onChange={(e:any) => {
                                            
                                            if(e.target.files[0] !== undefined){
                                                setFileNameSN(e.target.files[0].name);
                                            }else{
                                                setFileNameSN("");
                                            }
                                            }}
                                        />
                                    <p id="fileName">{fileNameSN}</p>
                                </label>
                            </div>
                        }

                        <div>
                            <label><span className="requiredMark">*</span> Name of item </label> 
                            <input required className="verify-form" type="text"  maxLength ={140} value={values.mintName}  onChange={handleInputChange} name="mintName"  />
                        </div>

                        <div>
                            <label><span className="requiredMark">*</span> Item description </label> 
                            <textarea id="item-desc" required className="verify-form"  maxLength ={600} value={values.mintDesc}  onChange={handleInputChange} name="mintDesc"  />
                            { /*<input required className="verify-form"  maxLength ={400} value={values.mintDesc}  onChange={handleInputChange} name="mintDesc" /> */}
                        </div>

                        <div>
                            <label>Url link to item</label> 
                            <input  className="verify-form" type="url" pattern="https?://.+" size={2048}  value={values.mintUrl}  onChange={handleInputChange} name="mintUrl"  />
                        </div>
                    </fieldset>

                </div>
         

                <div className="cards">
                    <fieldset disabled={!isApproved} className="tooltip" style={{ width:"100%"}}>                

                        <h3 style={{"marginTop": "2rem"}}><strong>Authorship statement</strong></h3>
                        
                 
                        <div >
                            <label><span className="requiredMark">*</span> Author full name </label> 
                            <input  required disabled className="verify-form disabled" type="text"  value={values.mintAuthor}  onChange={handleInputChange} name="mintAuthor"  />
                        </div>
                        <div>
                            <label>Author's description</label> 
                            <textarea id="author-desc" className="verify-form"  maxLength ={500} value={values.mintAuthorDesc}  onChange={handleInputChange} name="mintAuthorDesc"  />
                        </div>

                        <div>
                            <label><span className="requiredMark">*</span> Brand name </label> 
                            <input  required disabled className="verify-form disabled" type="text"  value={values.mintBrand}  onChange={handleInputChange} name="mintBrand"  />
                        </div>
                        <div>
                            <label>Brand description</label> 
                            <textarea id="brand-desc" className="verify-form"  maxLength ={400} value={values.mintBrandDesc}  onChange={handleInputChange} name="mintBrandDesc"  />
                        </div>


                    </fieldset>

                </div>    
               

                <div className="cards">
                    <fieldset disabled={!isApproved} className="tooltip">                
                       
                        <div>
                            <label>Production date</label> 
                            <input  className="verify-form" type="text"  value={values.mintDate}  onChange={handleInputChange} name="mintDate"  />
                        </div>

                        <div>
                            <label>Certificate of authenticity url</label> 
                            <input  className="verify-form" type="url" pattern="https?://.+" size={2048}  value={values.mintCertUrl}  onChange={handleInputChange} name="mintCertUrl"  />
                        </div>

                        <div>
                            <label>Brand website</label> 
                            <input  className="verify-form" type="url" pattern="https?://.+" size={2048}  value={values.mintBrandUrl}  onChange={handleInputChange} name="mintBrandUrl"  />
                        </div>

                    </fieldset>
               </div>   
            </div>
            
            <div className="pro-time-btn">
                <button id="timestamp-nft" disabled={emptyUpload} className="btn-primary" type={"submit"}>Create Proof of Authorship</button>
            </div>
            
            
        </form>
    )

}
