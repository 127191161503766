

export default function AboutText() {

  return (
    <div className="visual-wrap">
    <div className="visual-paragraph">
      About MyNextNFT platform
    </div>
    </div>

  );
};
