import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  Code,
  Link,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";

import { Store } from 'react-notifications-component';

let serverUrl = window.location.origin;

function showError(msg:string){
    Store.addNotification({
        title: "Error",
        message: msg,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
        duration: 8000,
        onScreen: true
        }
    })
}

interface VerifyBrandProps {
    onBrand: (value: string) => void
}


const VerifyBrand = (props: VerifyBrandProps) => {
    const [brands, setBrands] = useState();

    const groupedOptions = [
        {
          options: brands
        }
    ];

    useEffect(() => {
        const requestOptions: RequestInit = {
            method: 'GET',
        };
        
        fetch(serverUrl  + '/api/v1/brands', requestOptions).then(async response => {
            
            // check for error response
            if (!response.ok ) {
                // get error message from body or default to response status
                console.log("Error in response code")
                const error = await response.text();
                return Promise.reject(error);
        
            }else{
        
                const data1 = await response.text();
                const data = JSON.parse(data1);
                console.log(data.data);
                if(data.success) {
                    try {
                        setBrands(data.data)
                    } catch (error:any) {
                        showError(error.toString());
                    }
                    
                } else {
                    throw new Error(data1);
                }
            }
        })
        .catch(error => {
            showError(error.toString());
        });
    }, []);

    
    return (
        <FormControl>
          <FormLabel>
            Select brand
          </FormLabel>
          <Select
            name="colors"
            options={groupedOptions}
            placeholder="Select brand"
            closeMenuOnSelect={true}
            id="brandSelectingInput"
            onChange={(option: any) => {
                props.onBrand(option.value);
            }}
          />
        </FormControl>
    );
}


export default VerifyBrand;
