import React, { useEffect, useState } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Tag,
    Button,
  } from '@chakra-ui/react'
import { Store } from 'react-notifications-component'
import { useNavigate, useSubmit } from 'react-router-dom'

const defaultImage = require ("../../img/unavailable.png");

interface HistoryEntry {
  
    name: string
    ts: any
    email: string
    approved_by: string
}

interface ManagerDataProps {
    data: HistoryEntry[]
    approvable?: boolean,
    removeEntry: (email: string) => void
    tabType : string
}

export default function ManagerTable(props:ManagerDataProps) {

    let textAction = { "pending": "Action", "approved": "Approved By", "blocked": "Blocked By"}

    let action = "Action";

    switch (props.tabType) {
		case "pending":
			action = "Action"
            break;
		case "approved":
			action = "Approved By"
            break;
		case "blocked":
			action = "Blocked By"
            break;
        default:
            action  = "" 
	}


    const handle = (approved: boolean, email: string) => {
        return function(e: any) {
            // logic for approving user
            fetch('/managerData', {
                method: "POST",
                body: JSON.stringify({
                    'email': email,
                    'approve': approved,
                })
            }).then(async (resp) => {
                if(!resp.ok) {
                    let data = await resp.text();
                    return Promise.reject(data);
                } else {
                    let data = await resp.json();
                    if(data.success) {
                        props.removeEntry(email);
                    }
                }
            }).catch((reason) => {
                Store.addNotification({
                    title: "Error",
                    message: reason.toString(),
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 8000,
                        onScreen: true,
                        pauseOnHover: true,
                    }
                })
            })
        }
    }

    let count = 0;
    return (
        <TableContainer className="table-history">
            <Table size='md' className="table-tiny" border="0px;">
                <Thead>
                    <Tr key="Head">
                        <Th w="10px"></Th>
                        <Th >User</Th>
                        <Th>Brand</Th>
                        <Th w="100px">Timestamp</Th>
                        <Th w="250px">{action}</Th>
                    </Tr>
                </Thead>
                <Tbody>

                    {props.data === null && 
                        <Tr key="NoEntriesAvailable">
                            <Td></Td><Td></Td><Td>no entries available</Td><Td></Td><Td></Td>
                        </Tr> 
                    }
               
                    {props.data !== null && props.data.map((entry) => {
                        count ++;
                        let action = <>{entry.approved_by}</>
                        if(props.approvable) {
                            action = 
                            (<>
                                <Button onClick={handle(true, entry.email)} style={{marginRight:"10px"}}>Approve</Button> 
                                <Button onClick={handle(false, entry.email)}>Decline</Button>
                            </>)
                        }
                        
                        return (
                            <Tr key={entry.name}>
                                <Td style={{ color: "rgb(112, 122, 131)"}}>{count}</Td>
                                <Td >{entry.email}</Td>
                                <Td >{entry.name}</Td>
                                <Td >{entry.ts}</Td>
                                <Td>{action}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    )
}