import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell} from '@fortawesome/free-solid-svg-icons'
import { faBars} from '@fortawesome/free-solid-svg-icons'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom';
import { isEntityName } from 'typescript';
import WalletConnect from '../Wallet/WalletConnect';
import logo from "../../img/avatar.svg";
import { Input, InputGroup, InputLeftElement, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from 'react';
import logo_mynextID from  "../../img/logoMynextNFT-pro.svg";
import {  SearchIcon } from '@chakra-ui/icons';

interface Navbar {
    onPageChange: (location: string) => void,
    onSearchChange: (searchText: string) => void
    notification: number,
    entity: any,
    onPage: string,
    refresh: (params: any) => any;
}

export const navItemsAuth = [

    {
      id: 1,
      title: "DASHBOARD",
      path: "dashboard",
      cName: "",
    },{
      id: 2,
      title: "CREATE A NFT",
      path: "protect",
      cName: "",
    },{
      id: 3,
      title: "VERIFY",
      path: "verify",
      cName: "",
    },{
        id: 4,
        title: "FEEDBACK",
        path: "feedback",
        cName: "",
    },{
      id: 5,
      title: "ABOUT",
      path: "about",
      cName: "",
    },
  ];

const NavbarPro = (props: Navbar) => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    let ts: NodeJS.Timeout | undefined
    // console.log("Props : --------------------");
    // console.log(props);


    const handleChangeInput = (event:any) => {
        setSearch(event.target.value);
        if(ts) {
            clearTimeout(ts);
        }

        ts = setTimeout(() => {
            props.onSearchChange(event.target.value);
        }, 500)
    };

    return (<>
        <nav className="navbar">
            <div id="logo-mobile"  className="sidebar__img">
                <div id="logoIcon"><img src={logo_mynextID} alt="logo" className="clickable" onClick={ () => { props.onPageChange("/protect");navigate("/protect")}}/></div>
            </div>

            <div className="navbar__right site-navigation "> 
                {!open && <>
                    <div className="mobile__sidebar material-symbols-rounded" onClick={() => { setOpen(true) }}><FontAwesomeIcon icon={faBars} size="1x" /></div>
                    <WalletConnect pathname={props.onPage} />
                    {props.entity.manager && <>
                    <div className="notifications" onClick={ () => {navigate("/manager")}}>

                      
                        <div className="bubble">{props.notification}</div>
                        <a href="">
                            <FontAwesomeIcon icon={faBell} size="1x" />
                        </a>
                        
                    </div>
                    </>}
                    
                    {props.onPage  === "dashboard" ||props.onPage  === "main" ?

                    <InputGroup  id="dashboard__search" className="tooltipSearch" style={{marginLeft:"45px"}}>

                          <InputLeftElement 
                            pointerEvents='none'
                            color='gray.300'
                            fontSize='1.2em'
                            
                            children={<>
                            
                                <SearchIcon color='gray.300'  >
                                        
                                </SearchIcon>

                                <span className="tooltiptextSearch">
                                    <strong>Search by:</strong>
                                    <ul>
                                        <li>NFT name</li>
                                        <li>Brand</li>
                                        <li>Author name</li>
                                        <li>Author address</li>
                                        <li>Nft id</li>
                                        <li>Asset id</li>
                                        <li>Serial number</li>
                                        <li>NFT name</li>
                                    </ul>
                                </span>
                                
                            </>}/>
                                
                            <Input value={search} placeholder={'Search your NFT'} style={{backgroundColor:"white", color:"black",maxWidth:"230px"}}    onChange={handleChangeInput}/> 
                         </InputGroup>
                       :<></>
                    }
                    
            
                </> }
                {open && <>
                    <div className="pro_dropdown_exit" onClick={() => { setOpen(false) }}><FontAwesomeIcon icon={faX} size='2x' /></div>
                    <div className='pro_dropdown_mini_menu' >
                        
                         {props.entity.manager && <div key={0} className={("manager" === props.onPage) ? "active": "" }>
                                <Link to="manager" onClick={() => { props.onPageChange("manager"); setOpen(false); }}>MANAGER</Link>
                            </div>}
                            
                        <br></br>
                        {navItemsAuth.map(item => {

                            if(item.path === "feedback"){
                                return (
                                    <div key={item.id} className={(item.path === props.onPage) ? "active": "" }>
                                        <Link to={item.path} onClick={() => { props.onPageChange(item.path);props.refresh(false); setOpen(false); }}>{item.title}</Link>
                                    </div>
                                )
                            }
                            return (
                            <div key={item.id} className={(item.path === props.onPage || (props.onPage == "main" && item.path === "dashboard")) ? "active": "" }>
                                <Link to={item.path} onClick={() => { props.onPageChange(item.path); setOpen(false); }}>{item.title}</Link>
                            </div>
                            )
                        })}

                        <div key={100} className="logout-pro">
                                <Link to="logout" onClick={() => { props.onPageChange("logout"); setOpen(false); }}>Logout</Link>
                        </div>
                    </div>
                </>}
            </div>
        </nav><div className="cr cr-top cr-right cr-sticky cr-red">BETA</div>
    </>);
};

export default NavbarPro;

